import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { Button } from 'components/Button/Button';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import React, { useState, useEffect } from 'react';
import getIcon from 'utils/getIcon';
import { Audience, Language } from 'utils/languages';

interface PricingProps {
  language: Language;
  audience: Audience;
}

export function Pricing({ language = Language.FR, audience = Audience.AGENCIES }: PricingProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <div className="flex flex-col gap-12 items-center align-middle">
      <H2Title text="Prêt à passer à la vitesse supérieure ?" color="white" position="center" />
      <div className="flex lg:flex-row flex-col items-center align-items justify-between gap-8 px-10">
        <Card
          width="md"
          height={isLargeScreen ? 'xxl' : 'xl'}
          background="white"
          textColor="black"
          horizontalPadding="lg"
          position="justify-start"
        >
          <div className="flex flex-col align-middle items-center gap-1 px-4 py-4 h-20 w-full">
            <H3Title text="FREE"></H3Title>
            <p className="text-base text-justify">Pendant 15 jours</p>
          </div>
          <div className="flex justify-start">
            <H2Title text="0€"></H2Title>
          </div>
          <div className="flex flex-col gap-10 items-center">
            <Button
              text="Inscrivez-vous"
              size="p"
              href="/specks/pre-inscription"
              openInNewTab={false}
              backgroundColor="white"
              textColor="black"
              borderColor="border-1 border-pink-500"
              onHover="hover:bg-pink-500 hover:text-white hover:border-pink-500"
            />
            <div className="flex flex-col justify-start">
              <ul>
                <li>✅ Produits illimités</li>
                <li>✅ Composants illimités</li>
                <li>✅ Accompagnement personnalisé</li>
                <li>❌ Gestion des droits</li>
                <li>❌ Centralisation des règles logiques</li>
                <li>❌ Intégration Figma</li>
              </ul>
            </div>
          </div>
        </Card>
        <Card
          width="md"
          height={isLargeScreen ? 'xxl' : 'xl'}
          background="white"
          textColor="black"
          horizontalPadding="lg"
          position="justify-start"
        >
          <div className="flex flex-col align-middle items-center gap-8 px-4 py-4 h-20 w-full">
            <H3Title text="STANDARD"></H3Title>
          </div>
          <div className="flex flex-row items-center gap-1">
            <H2Title text="422€"></H2Title>
            <p className="text-base "> HT / mois</p>
          </div>
          <div className="flex flex-col gap-10 items-center">
            <Button
              text="Contactez-nous"
              size="p"
              href="https://calendly.com/amandine-musseau/30"
              backgroundColor="white"
              textColor="black"
              borderColor="border-1 border-pink-500"
              onHover="hover:bg-pink-500 hover:text-white hover:border-pink-500"
            />
            <div className="flex flex-col justify-start">
              <ul>
                <li>✅ Produits illimités</li>
                <li>✅ Composants illimités</li>
                <li>✅ {'Canal slack dédié'}</li>
                <li>❌ Gestion des droits</li>
                <li>❌ Centralisation des règles logiques</li>
                <li>❌ Intégration Figma</li>
              </ul>
            </div>
          </div>
        </Card>
        <Card
          width="md"
          height={isLargeScreen ? 'xxl' : 'xl'}
          background="white"
          textColor="black"
          horizontalPadding="lg"
          position="justify-start"
        >
          <div className="flex flex-col align-middle items-center gap-8 px-4 py-4 h-20 w-full">
            <H3Title text="PREMIUM"></H3Title>
          </div>
          <div className="flex flex-row items-center gap-1">
            <H2Title text="622€"></H2Title>
            <p className="text-base "> HT / mois</p>
          </div>
          <div className="flex flex-col gap-10 items-center">
            <p className="italic text-base lg:text-xl pt-4">Incoming</p>
            <ul>
              <li>✅ Produits illimités</li>
              <li>✅ Composants illimités</li>
              <li>✅ Canal Slack dédié</li>
              <li>✅ Gestion des droits</li>
              <li>✅ Centralisation des règles logiques</li>
              <li>✅ Intégration Figma</li>
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
}
