import React from 'react';
import {
  type LetterSpacing,
  type TextBreak,
  type TextColor,
  type TextDecoration,
  type TextMarginBottom,
  type TextMarginLeft,
  type TextMarginRight,
  type TextMarginTop,
  type TextPosition,
  type TextSize,
  type TextWeight,
  type TextWhiteSpace,
  letterSpacingStyle,
  textBreakStyle,
  textColorStyle,
  textDecorationStyle,
  textMarginBottom,
  textMarginLeft,
  textMarginRight,
  textMarginTop,
  type textOverflow,
  textOverflowStyle,
  textPositionStyle,
  textSizeStyle,
  textWeightStyle,
  textWhiteSpaceStyle,
  type TextBackgroundColor,
  textBackgroundColorStyle,
  type TextBackgroundRounded,
  type TextBackgroundHorizontalPadding,
  textBackgroundRoundedStyle,
  textBackgroundHorizontalPaddingStyle,
} from 'components/Blog_Components/ArticleComponents/Text/Text';
import uuid from 'react-uuid';

import concatClassNames from 'utils/classNames';

/*
 ** p : new Line
 ** span : inline
 ** complex : an array of TextProps encapsulated in a p
 */
type TextType = 'p' | 'span' | 'complex' | 'br' | 'wbr';

export type TextProps =
  | {
      content?: string | JSX.Element | TextProps[];
      contentType: TextType;
      size?: TextSize;
      weight?: TextWeight;
      color?: TextColor;
      backgroundColor?: TextBackgroundColor;
      backgroundRounded?: TextBackgroundRounded;
      backgroundHorizontalPadding?: TextBackgroundHorizontalPadding;
      tracking?: LetterSpacing;
      position?: TextPosition;
      whitespace?: TextWhiteSpace;
      textBreak?: TextBreak;
      textDecoration?: TextDecoration;
      marginTop?: TextMarginTop;
      marginRight?: TextMarginRight;
      marginBottom?: TextMarginBottom;
      marginLeft?: TextMarginLeft;
      overFlow?: textOverflow;
      hidden?: boolean;
      style?: string;
    }
  | {
      content?: never;
      contentType?: never;
      size?: never;
      weight?: never;
      color?: never;
      backgroundColor?: never;
      backgroundRounded?: never;
      backgroundHorizontalPadding?: never;
      tracking?: never;
      position?: never;
      whitespace?: never;
      textBreak?: never;
      textDecoration?: never;
      marginTop?: never;
      marginRight?: never;
      marginBottom?: never;
      marginLeft?: never;
      overFlow?: never;
      hidden?: boolean;
      style?: never;
    };

type RichTextProps =
  | {
      fragments: TextProps[];
      loading?: false;
    }
  | {
      fragments?: never;
      loading: true;
    };

function getSpanTextFragment(fragment: TextProps): JSX.Element {
  return (
    <span
      key={uuid()}
      className={concatClassNames(
        fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
        fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
        fragment.backgroundHorizontalPadding !== undefined
          ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
          : '',
        fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
        fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
        fragment.color !== undefined ? textColorStyle[fragment.color] : '',
        fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
        fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
        fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
        fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
        fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
        fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
        fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
        fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
        fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
        fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
        'w-fit',
        fragment.style !== undefined ? fragment.style : '',

        'max-w-full',
      )}
      hidden={fragment.hidden ?? false}
    >
      {fragment.content as string}
    </span>
  );
}

function getPTextFragment(fragment: TextProps): JSX.Element {
  return (
    <p
      key={uuid()}
      className={concatClassNames(
        fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
        fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
        fragment.backgroundHorizontalPadding !== undefined
          ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
          : '',
        fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
        fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
        fragment.color !== undefined ? textColorStyle[fragment.color] : '',
        fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
        fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
        fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
        fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
        fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
        fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
        fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
        fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
        fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
        fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
        fragment.style !== undefined ? fragment.style : '',

        'w-fit',
        'max-w-full',
      )}
      hidden={fragment.hidden ?? false}
    >
      {typeof fragment.content === 'string' && fragment.content}
      {typeof fragment.content === 'object' &&
        (fragment.content as TextProps[]).map((frag) => getComplexTextFragment(frag))}
    </p>
  );
}

function getComplexTextFragment(fragment: TextProps): string | JSX.Element | JSX.Element[] {
  if (fragment.contentType === 'p') return <span>{'Error, only span is allowed in complex fragments'}</span>;
  else if (fragment.contentType === 'span') return getSpanTextFragment(fragment);
  else if (fragment.contentType === 'br') return <br key={uuid()} />;
  else if (fragment.contentType === 'wbr') return <wbr key={uuid()} />;
  return 'ERROR';
}

function getRichTextFragment(fragment: TextProps): JSX.Element {
  if (fragment.contentType === 'p') {
    return getPTextFragment(fragment);
  } else if (fragment.contentType === 'span') {
    return getSpanTextFragment(fragment);
  } else if (fragment.contentType === 'complex') {
    return getPTextFragment(fragment);
  } else if (fragment.contentType === 'br') {
    return <br key={uuid()} />;
  } else if (fragment.contentType === 'wbr') {
    return <wbr key={uuid()} />;
  }
  return <></>;
}
export function RichText({ fragments, loading = false }: RichTextProps): JSX.Element {
  // TODO : Loading with the correct size
  if (loading) {
    return (
      <div className={concatClassNames('w-40', 'h-5', 'my-0.5', 'bg-gray-50', 'rounded-md', 'animate-pulse')}></div>
    );
  } else {
    return <div>{fragments?.map((frag) => getRichTextFragment(frag))}</div>;
  }
}
