import { faLandmarkFlag } from '@fortawesome/free-solid-svg-icons';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/Button/Button';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { Language } from 'utils/languages';

export interface OneLinerProps {
  language: Language;
}
export function OneLiner({ language = Language.FR }: OneLinerProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <div id="eye_catcher" className={concatClassNames('flex flex-col')}>
      <div className={concatClassNames('flex flex-col', 'items-center gap-14 lg:gap-6 pt-4 lg:pt-0')}>
        <div className={concatClassNames('')}>
          <H1Title
            text={
              language === Language.ENG
                ? 'Experience the power of a comprehensive specification.'
                : "Découvrez la puissance d'une spécification exhaustive."
            }
            color="white"
            position="center"
            weight="bold"
          />
        </div>

        <div className="flex flex-col items-start gap-2 ">
          <H3Title color="white" text="✅  Sécurisez votre projet"></H3Title>
          <H3Title color="white" text="✅  Collaborez plus efficacement"></H3Title>

          <H3Title color="white" text="✅  Optimisez vos estimations"></H3Title>
        </div>

        <div className="pt-4">
          <Button
            href="https://calendly.com/amandine-musseau/30  "
            text="Je demande une démo"
            backgroundColor="white"
            horizontalPadding="3"
            verticalPadding="2"
            textColor="black"
            size="p"
            onHover="hover:bg-gradient1-from focus-visible:outline focus-visible:outline-2 hover:text-white"
          />
        </div>
      </div>
    </div>
  );
}
