/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import { SpecksHeader } from 'components/Sections/Header/SpecksHeader';
import concatClassNames from 'utils/classNames';
import { Audience, Language } from 'utils/languages';
import { Pricing } from 'components/Sections/Pricing';
import { Footer } from 'components/Sections/Footer';

export function PricingPage(): JSX.Element {
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient1-from to-gradient1-to gap-3 lg:gap-2')}
    >
      <SpecksHeader language={Language.FR} />
      <Pricing language={Language.FR} audience={Audience.AGENCIES} />
      <Footer language={Language.FR} textColor="white" />
    </div>
  );
}
