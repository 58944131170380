import { animate, motion, useInView, useMotionValue, useSpring, useTransform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';

interface CounterProps {
  to: number;
}

function Counter({ to }: CounterProps): JSX.Element {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const count = useMotionValue(0);
  const rounded = useTransform(count, (x) => Math.round(x * 10) / 10);

  useEffect(() => {
    if (isInView) {
      const animation = animate(count, to, {
        duration: 1.5,
      });

      return animation.stop;
    }
  }, [isInView]);

  return (
    <>
      <span className={concatClassNames('p-1.5', 'pt-3')}>
        <motion.span className={concatClassNames('text-3xl lg:text-4xl', 'font-circularStd-Bold')} ref={ref}>
          {rounded}
        </motion.span>
        <span className={concatClassNames('text-base', 'font-circularStd-Bold')}>%</span>
      </span>
    </>
  );
}
export interface NumberedStatementProps {
  language: Language;
}
export function NumberedStatement({ language = Language.FR }: NumberedStatementProps): JSX.Element {
  return (
    <div
      id="stats"
      className={concatClassNames(
        'lg:relative',
        'flex flex-col',
        'gap-3',
        'mt-12',
        'gap-4',
        'pt-11 pb-20 px-10',
        'bg-white',
      )}
    >
      <p className={concatClassNames('hidden lg:block', 'absolute', 'right-3 bottom-0', 'text-xs')}>
        {language === Language.ENG ? '*Sample: 36 interviewed companies' : '*Echantillon: 36 entreprises interrogées'}
      </p>
      <h1
        className={concatClassNames(
          'text-center p-4',
          'text-[22px] lg:text-[38px] font-circularStd-Bold',
          'bg-gradient-to-r from-gradient1-from to-gradient1-to inline-block text-transparent bg-clip-text',
        )}
      >
        {language === Language.ENG
          ? 'Write everything that is mandatory for product development, but nothing more.'
          : `Tout ce qui est nécessaire pour spécifier un applicatif, mais uniquement ce qui est nécessaire.`}
      </h1>
      <div className={concatClassNames('lg:grid lg:grid-cols-2 lg:justify-center lg:text-[20px]')}>
        <div className={concatClassNames('flex flex-col items-center ')}>
          <Counter to={90.7} />
          <span className={concatClassNames('text-center', 'font-circularStd-Bold')}>
            {language === Language.ENG ? 'of Product Teams' : `des équipes Produit`}
          </span>
          <span className={concatClassNames('text-center', 'font-circularStd-Regular', 'w-[90%]')}>
            {language === Language.ENG
              ? 'admit to forgetting elements in their specification, causing interruptions and rework*'
              : `reconnaissent oublier des éléments dans leurs spécifications, entraînant interruptions et coûts de reworking`}
          </span>
        </div>
        <div className={concatClassNames('flex flex-col', 'items-center')}>
          <Counter to={92.5} />
          <span className={concatClassNames('text-center', 'font-circularStd-Bold')}>
            {language === Language.ENG ? 'of Tech Teams' : `des équipes Tech`}
          </span>
          <span className={concatClassNames('text-center', 'font-circularStd-Regular', 'w-[90%]')}>
            {language === Language.ENG
              ? 'acknowledge that they skim through dense specifications*'
              : `reconnaissent survoler les spécifications, surtout lorsqu'elles sont denses et avec beaucoup de texte`}
          </span>
        </div>
      </div>
    </div>
  );
}
// *Sample: 36 interviewed compagnies
