import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import { RichText } from 'components/RichText/RichText';
import React, { useEffect, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Audience, Language } from 'utils/languages';
export interface UseCasesProps {
  language: Language;
  audience: Audience;
}

export function UseCases({ language = Language.FR, audience = Audience.AGENCIES }: UseCasesProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);
  return (
    <div id="useCases">
      <div
        className={concatClassNames(
          'flex flex-col',
          'relative',
          'font-circularStd-Light',
          'lg:items-center',
          'gap-7 lg:gap-10 px-4 lg:px-20 xl:px-40 pt-16',
          'lg:mt-28',
        )}
      >
        <H2Title text="Boostez vos projets avec Specks ! ✨ " color="white" position="center" />
        <div
          className={concatClassNames(
            'text-white hidden lg:text-base lg:gap-2 lg:flex lg:flex-row font-circularStd-Bold',
          )}
        ></div>

        <div>
          <div
            id="validation_du_perimetre_projet"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-10 items-center',
              'py-4 pb-12 px-8 gap-4',
              'lg:px-10 lg:py-10',
              'bg-white',
              'rounded-lg',
            )}
          >
            <div className="lg:w-[40%] w-[80%]">
              <img alt="Réunion de cadrage" src="/imgs/194.High-Five.png"></img>
            </div>
            <div className={concatClassNames('flex flex-col gap-10 lg:gap-10 lg:w-[50%]')}>
              <H3Title
                text="Validation de projet avec le client"
                position={isLargeScreen ? 'left' : 'center'}
              ></H3Title>
              <div className="flex flex-col gap-8">
                <P
                  text="Grâce à Specks, pas besoin d’être un expert technique pour se poser les bonnes questions et challenger votre client."
                  position="justify"
                ></P>
                <P
                  text="Projetez votre client dans ses besoins via une interface visuelle dès les premiers échanges, pour valider sereinement le périmètre fonctionnel et réduire les risques d'ambiguïtés."
                  position="justify"
                ></P>
                <P
                  text="Affirmez votre expertise, augmentez de 25% votre taux de conversion client et réduisez jusqu’à 20% le temps de réalisation de vos projets."
                  position="justify"
                  weight="bold"
                  style="italic"
                ></P>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            id="validation_du_perimetre_projet"
            className={concatClassNames(
              'flex flex-col lg:flex-row-reverse lg:gap-10 items-center',
              'py-4 pb-12 px-8 gap-4',
              'lg:px-10 lg:py-10',
              'bg-white',
              'rounded-lg',
            )}
          >
            <div className="lg:w-[40%] w-[80%]">
              <img alt="Réunion de cadrage" src="/imgs/111.Business-plan.png"></img>
            </div>
            <div className={concatClassNames('flex flex-col gap-10 lg:gap-10 lg:w-[50%]')}>
              <H3Title text="Optimisation de la planification" position={isLargeScreen ? 'left' : 'center'}></H3Title>
              <div className="flex flex-col gap-8">
                <P
                  text="Augmentez de 30% l'efficacité de vos séances de planification, grâce à une clarification des tâches et des dépendances."
                  position="justify"
                ></P>
                <P
                  text="Specks précise et accélère les estimations, garantissant le respect des délais fixés."
                  position="justify"
                ></P>
                <P
                  text="Economisez jusqu’à 1000€ par projet, grâce à une meilleure anticipation des contraintes techniques."
                  position="justify"
                  weight="bold"
                  style="italic"
                ></P>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            id="validation_du_perimetre_projet"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-10 items-center',
              'py-4 pb-12 px-8 gap-4',
              'lg:px-10 lg:py-10',
              'bg-white',
              'rounded-lg',
            )}
          >
            <div className="lg:w-[40%] w-[80%]">
              <img alt="Réunion de cadrage" src="/imgs/269.Page-Loading-Speed.png"></img>
            </div>
            <div className={concatClassNames('flex flex-col gap-10 lg:gap-10 lg:w-[50%]')}>
              <H3Title
                text="Accélération et maîtrise du développement"
                position={isLargeScreen ? 'left' : 'center'}
              ></H3Title>
              <div className="flex flex-col gap-8">
                <P
                  text="L’utilisateur final de la spécification est le développeur : grâce à Specks assurez-vous de lui donner TOUTES les informations dont il a besoin et UNIQUEMENT les informations dont il a besoin."
                  position="justify"
                ></P>
                <P
                  text="Specks permet une mise en action rapide, avec une économie de temps et une amélioration de la productivité, pour un démarrage optimal de chaque projet."
                  position="justify"
                ></P>
                <P
                  text="Gagnez jusqu’à 3500€ de plus par projet, en garantissant aux développeurs des informations exhaustives, pour un développement sans surprises."
                  position="justify"
                  weight="bold"
                  style="italic"
                ></P>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            id="validation_du_perimetre_projet"
            className={concatClassNames(
              'flex flex-col lg:flex-row-reverse lg:gap-10 items-center',
              'py-4 pb-12 px-8 gap-4',
              'lg:px-10 lg:py-10',
              'bg-white',
              'rounded-lg',
            )}
          >
            <div className="lg:w-[40%] w-[80%]">
              <img alt="Réunion de cadrage" src="/imgs/184.Workspace.png"></img>
            </div>
            <div className={concatClassNames('flex flex-col gap-10 lg:gap-10 lg:w-[50%]')}>
              <H3Title text="Documentation partagée" position={isLargeScreen ? 'left' : 'center'}></H3Title>
              <div className="flex flex-col gap-8">
                <P
                  text="Specks propose un espace de documentation centralisé qui permet une organisation logique et un partage facile des informations clés."
                  position="justify"
                ></P>
                <P
                  text="Specks améliore la collaboration et l'efficacité au sein de l'équipe projet, en regroupant l’ensemble des documents du projet (API externes, cahier des charges, charte graphique, etc.)."
                  position="justify"
                ></P>
                <P
                  text="Diminuez de 50% le temps consacré à la recherche d'informations grâce à une meilleure gestion et accessibilité des connaissances."
                  position="justify"
                  weight="bold"
                  style="italic"
                ></P>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
