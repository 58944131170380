/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';
import { SliderPartners } from 'components/SliderPartners/SliderPartners';

import 'swiper/css';
import 'swiper/css/navigation';
import { Form } from 'components/Form/Form';
import { Features } from 'components/Features/Features';
import { SpecksHeader } from 'components/Sections/Header/SpecksHeader';
import { OneLiner } from 'components/Sections/Oneliner';
import { Medley } from 'components/Sections/Medley';
import { NumberedStatement } from 'components/Sections/NumberedStatement';
import { SpecificationTabs } from 'components/Sections/SpecificationTabs';
import { Roll } from 'components/Sections/Roll';
import { Testimonies } from 'components/Sections/Testimonies';
import { CTA } from 'components/Sections/CTA';
import { ValueProposal } from 'components/Sections/ValueProposal';
import concatClassNames from 'utils/classNames';
import { Footer } from 'components/Sections/Footer';
import { Audience, Language } from 'utils/languages';

const language: Language = Language.ENG;

export function EngHomePage(): JSX.Element {
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient1-from to-gradient1-to gap-3 lg:gap-4')}
    >
      <SpecksHeader language={language} />
      <div
        className={concatClassNames('flex flex-col lg:grid lg:grid-cols-2 lg:px-4 lg:justify-center lg:items-center')}
      >
        <OneLiner language={language} />
        <Medley language={language} />
      </div>
      <ValueProposal language={language} audience={Audience.ENTEPRISES} />
      {/* <CompareSlider /> */}
      {/* <Problems /> */}
      <NumberedStatement language={language} />
      <SpecificationTabs language={language} />
      <Features language={language} audience={Audience.ENTEPRISES} />
      <Roll language={language} audience={Audience.ENTEPRISES} />
      <Testimonies language={language} />
      <CTA language={language} />
      <SliderPartners language={language} />
      {/* <Form /> */}
      <Footer language={language} />
    </div>
  );
}
