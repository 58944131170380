import { LeftMenu } from 'components/Blog_Components/LeftMenu/LeftMenu';
import { SpecksHeader } from 'components/Sections/Header/SpecksHeader';
import React, { useEffect, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { type Article, articles, Categories } from './Articles/Contenu_Article';
import uuid from 'react-uuid';
import { ArticlePage } from './Articles/ArticlePage';
import { ArticleCard } from './Articles/ArticleCard';
import { CardContainer } from 'components/Blog_Components/ArticleComponents/Card/CardContainer';
import { Chip } from './Chip/Chip';
import { CategoryChip } from './CategoryChip/CategoryChip';
import { Language } from 'utils/languages';
import { ArticlesPreview } from './Articles/ArticlesPreview';

const categories: Categories[] = [Categories.PRODUCT, Categories.TECH];
export function BlogHomePage(): JSX.Element {
  return (
    <div className={concatClassNames('flex flex-col bg-gradient-to-r h-screen bg-[#f7f4f3] gap-3 lg:gap-4')}>
      <SpecksHeader language={Language.FR} />
      <ArticlesPreview categories={categories}></ArticlesPreview>
      {/* <LeftMenu /> */}
    </div>
  );
}
