import React from 'react';
import { type HTitleProps } from './H1Title';
import { textColorStyle, textPositionStyle, textWeightStyle } from './Blog_Components/ArticleComponents/Text/Text';
import concatClassNames from 'utils/classNames';

export function P({
  text,
  color = 'black',
  position = 'right',
  weight = 'medium',
  style = '',
}: HTitleProps): JSX.Element {
  return (
    <p
      className={concatClassNames(
        'text-[18px] lg:text-[18px] xl:text-[20px] font-circularStd-Light',
        textColorStyle[color],
        textPositionStyle[position],
        textWeightStyle[weight],
        style,
      )}
    >
      {text}
    </p>
  );
}
