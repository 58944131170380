/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { SliderPartners } from 'components/SliderPartners/SliderPartners';

import { Helmet } from 'react-helmet';
import 'swiper/css';
import 'swiper/css/navigation';
import { SpecksHeader } from 'components/Sections/Header/SpecksHeader';
import { OneLiner } from 'components/Sections/Oneliner';
import { Medley } from 'components/Sections/Medley';
import { Testimonies } from 'components/Sections/Testimonies';
import { CTA } from 'components/Sections/CTA';
import concatClassNames from 'utils/classNames';
import { Footer } from 'components/Sections/Footer';
import { Audience, Language } from 'utils/languages';
import { TrialProcess } from 'components/Sections/TrialProcess';
import { Roll } from 'components/Sections/Roll';
import { UseCases } from 'components/UseCases/UseCases';
import ScrollToAnchor from 'utils/PageAnchor';

export function FrHomePage(): JSX.Element {
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient1-from to-gradient1-to gap-3 lg:gap-2')}
    >
      <ScrollToAnchor />
      <Helmet>
        <title>Specks</title>
        <meta
          name="Le premier outil dédié à la spécification produit : sécurisez vos projets, collaborez efficacement et optimisez vos estimations"
          content="Specks-HomePage"
        />
      </Helmet>
      <SpecksHeader language={Language.FR} />
      <div
        className={concatClassNames(
          'flex flex-col lg:grid lg:grid-cols-2 lg:px-4 lg:justify-center lg:items-center lg:pt-4',
          // 'lg:h-screen',
        )}
      >
        <OneLiner language={Language.FR} />
        <div className={concatClassNames('hidden md:block')}>
          <Medley language={Language.FR} />
        </div>
      </div>
      <UseCases language={Language.FR} audience={Audience.AGENCIES} />
      <Roll language={Language.FR} audience={Audience.AGENCIES} />
      <TrialProcess language={Language.FR} audience={Audience.AGENCIES} />

      <Testimonies language={Language.FR} />
      <div className={concatClassNames('')}>
        <CTA language={Language.FR} />
      </div>
      <SliderPartners language={Language.FR} />
      <Footer language={Language.FR} textColor="white" />
    </div>
  );
}
