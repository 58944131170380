import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { Squash as Hamburger } from 'hamburger-react';
import ArrowUpFromSquareIcon from 'icons/IconsArrowUpFromSquare';

export interface RedirectionProps {
  text: string;
  href: string;
  linkIcon?: boolean;
}

interface HeaderMenuProps {
  redirections: RedirectionProps[];
}

export function HeaderMenu({ redirections }: HeaderMenuProps): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpen(false);
  });

  return (
    <div ref={ref} className="lg:hidden ">
      <Hamburger toggled={isOpen} size={20} toggle={setOpen} color="white" />
      {isOpen && (
        <div className="fixed right-3 top-[3.5rem] bg-white rounded-lg p-2 shadow-2xl">
          <ul className="grid gap-2">
            {redirections.map((route) => {
              return (
                <li key={route.text} className="w-full p-[0.08rem] rounded-xl">
                  <a
                    onClick={() => {
                      setOpen(false);
                    }}
                    className={'flex gap-2 items-center w-full p-1 pl-3 pr-6 rounded-xl'}
                    href={route.href}
                  >
                    <span className="flex gap-1 text-lg">{route.text}</span>
                    {route.linkIcon && <ArrowUpFromSquareIcon color="black" size="sm" />}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
