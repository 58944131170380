import React from 'react';

import concatClassNames from 'utils/classNames';

import {
  type CardBodyProps,
  cardBodyHeightStyle,
  cardGapStyle,
  cardHorizontalPaddingStyle,
  cardItemsStyle,
  cardJustifyStyle,
  cardMarginStyle,
  cardOrientationStyle,
  cardVerticalPaddingStyle,
} from './Card.props';

export function CardBody({
  children,
  height = 'fill',
  margin = 'lg',
  items = 'center',
  verticalPadding = 'none',
  horizontalPadding = 'none',
  gap = 'lg',
  orientation = 'vertical',
  justify = 'center',
}: CardBodyProps): JSX.Element {
  // TODO : H-FULL ??????
  return (
    <div className={concatClassNames('relative', 'h-full w-full', cardMarginStyle[margin])}>
      <div
        className={concatClassNames(
          'flex',
          cardJustifyStyle[justify],
          cardOrientationStyle[orientation],
          cardItemsStyle[items],
          cardVerticalPaddingStyle[verticalPadding],
          cardHorizontalPaddingStyle[horizontalPadding],
          'w-full',
          'h-full',
          cardGapStyle[gap],
          cardBodyHeightStyle[height],
        )}
      >
        {children}
      </div>
    </div>
  );
}
