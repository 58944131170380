import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import concatClassNames from 'utils/classNames';
import { Footer } from 'components/Sections/Footer';
import { Language } from 'utils/languages';
import { PreInscriptionForm } from 'components/Sections/PreInscriptionForm';
import { SpecksHeader } from 'components/Sections/Header/SpecksHeader';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { H1Title } from 'components/H1Title';

const language: Language = Language.FR;

export function SpecksInscriptionPage(): JSX.Element {
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient1-from to-gradient1-to gap-3 lg:gap-4')}
    >
      <SpecksHeader language={language} />
      <div
        className={concatClassNames(
          'grid grid-row-2 lg:grid-cols-5 w-[100%] lg:h-[600px] text-white',
          'lg:mt-5 lg:p-10 mt-5',
          'gap-12 lg:gap-4',
        )}
      >
        <div className={concatClassNames('row-span-1 lg:col-span-3 flex flex-col gap-8 px-6  justify-center', 'h-fit')}>
          <H1Title text="Demandez votre période d'essai!" position="left" color="white" />
          <H2Title
            text="Complétez ce formulaire pour recevoir vos identifiants par mail 🙂"
            position="left"
            color="white"
          />
        </div>
        <div className={concatClassNames('lg:col-span-2', 'w-[100%] lg:h-[600px]')}>
          <PreInscriptionForm submitedMessage="Merci! L'équipe Specks va prendre contact avec vous." type="specks" />
        </div>
      </div>
      <Footer language={language} />
    </div>
  );
}
