import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';

import { type ChipBgColor } from './Chip.props';
import {
  Text,
  type LetterSpacing,
  type TextColor,
  type TextSize,
} from 'components/Blog_Components/ArticleComponents/Text/Text';

const bgColorStyle: Record<ChipBgColor, string> = {
  white: 'bg-white',
  transparent: 'bg-transparent',
  cyan: 'bg-blue-400',
  red: 'bg-red-500',
  orange: 'bg-orange-400',
  green: 'bg-green-500',
  yellow: 'bg-yellow-400',
  'miscelleanous-blue': 'bg-miscelleanous-blue',
  'yellow-400': 'bg-warning-400',
  'green-500': 'bg-success-500',
  'red-500': 'bg-error-500',
};

type ChipPaddingTop = 'none' | 'sm' | 'md';

const paddingTopStyle: Record<ChipPaddingTop, string> = {
  none: 'pt-0',
  sm: 'pt-0.5',
  md: 'pt-1',
};

type ChipPaddingBottom = 'none' | 'sm' | 'md';

const paddingBottomStyle: Record<ChipPaddingBottom, string> = {
  none: 'pb-0',
  sm: 'pb-0.5',
  md: 'pb-1',
};

type ChipPaddingHorizontal = 'sm' | 'md' | 'lg';

const paddingHorizontalStyle: Record<ChipPaddingHorizontal, string> = {
  sm: 'px-1',
  md: 'px-2',
  lg: 'px-2.5',
};

export type ChipBorderColor = 'none' | 'gray-50' | 'miscelleanous-blue' | 'red-500' | 'green-500' | 'yellow-400';

const borderColorStyle: Record<ChipBorderColor, string> = {
  none: '',
  'gray-50': 'border border-gray-50',
  'miscelleanous-blue': 'border border-miscelleanous-blue',
  'red-500': 'border border-error-500',
  'green-500': 'border border-success-500',
  'yellow-400': 'border border-warning-400',
};

type ChipProps =
  | {
      icon?: IconName;
      iconColor?: IconColor;
      textColor?: TextColor;
      textTracking?: LetterSpacing;
      label: string;
      iconSize?: IconSize;
      textSize?: TextSize;
      borderColor?: ChipBorderColor;
      bgColor?: ChipBgColor;
      paddingTop?: ChipPaddingTop;
      paddingBottom?: ChipPaddingBottom;
      paddingHorizontal?: ChipPaddingHorizontal;
      loading?: false;
    }
  | {
      icon?: never;
      iconColor?: never;
      textColor?: never;
      textTracking?: never;
      label?: never;
      iconSize?: never;
      textSize?: never;
      borderColor?: never;
      bgColor?: never;
      paddingTop?: never;
      paddingBottom?: never;
      paddingHorizontal?: never;
      loading: true;
    };

export function Chip({
  textColor = 'black',
  textTracking = 'normal',
  textSize = 'base',
  label = '',
  borderColor = 'gray-50',
  bgColor = 'white',
  paddingTop = 'none',
  paddingBottom = 'none',
  paddingHorizontal = 'sm',
}: ChipProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-row w-fit',
        bgColorStyle[bgColor],
        borderColorStyle[borderColor],
        'items-center gap-1 rounded-md content-center',
        paddingTopStyle[paddingTop],
        paddingBottomStyle[paddingBottom],
        paddingHorizontalStyle[paddingHorizontal],
        'align-middle',
        'h-fit',
      )}
    >
      <Text content={label} size={textSize} color={textColor} tracking={textTracking} />
    </div>
  );
}
