import React, { useRef, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { type Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { VideoPeople } from 'components/VideoPeople/VideoPeople';
import { MessageQuoteIcon } from 'icons/MessageQuote';
import 'swiper/css';
import 'swiper/css/pagination';
import { Language } from 'utils/languages';
import { H2Title } from 'components/H2Title';

interface SlideProps {
  name: string;
  quote: string;
  linkedin: string;
  job: string;
  company: string;
  favFeature: string;
  namePath?: string;
}

function Slide({ name, quote, linkedin, job, company, favFeature, namePath = name }: SlideProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <div
      className={concatClassNames(
        'flex flex-col lg:grid lg:grid-cols-5',
        'min-h-[500px] h-full',
        'p-3 ',
        'bg-white',
        'rounded-md',
        'flex-grow',
        'justify-between lg:justify-normal',
        'lg:w-full',
      )}
    >
      <div
        className={concatClassNames('lg:hidden flex flex-col h-full py-2 items-center gap-3 justify-center flex-grow')}
      >
        <MessageQuoteIcon isLargeScreen={isLargeScreen} />
        <p className={concatClassNames('text-center', 'text-gray-400', 'font-circularStd-Light')}>{`${quote} `}</p>
      </div>
      <VideoPeople
        videoPath={`/videos/Testimonies/${namePath}.mp4`}
        name={`${name}`}
        posterPath={`/imgs/thumbnails/${namePath}_B&W.png`}
      />

      <div
        className={concatClassNames(
          'flex flex-col h-full lg:flex-grow gap-2',
          'items-center justify-center',
          'lg:col-span-2',
        )}
      >
        <div className={concatClassNames('hidden lg:flex flex-col  py-2 px-6 items-center gap-3 justify-center')}>
          <MessageQuoteIcon isLargeScreen={isLargeScreen} />
          <p
            className={concatClassNames('text-center', 'text-gray-400', 'font-circularStd-Light text-xl')}
          >{`${quote}`}</p>
        </div>
        <a
          className={concatClassNames('flex flex-row', 'gap-2', 'items-center')}
          href={linkedin}
          target="_blank"
          rel="noreferrer"
        >
          <img className={concatClassNames('w-5 h-5')} src="/imgs/linkedin.png" alt="linkedin" />
          <span className={concatClassNames('text-center text-gray-400 lg:text-lg')}>{name}</span>
        </a>
        <p
          className={concatClassNames(
            'text-center',
            'font-bold',
            'text-xl lg:text-2xl',
            'font-circularStd-Medium',
            'bg-gradient-to-r from-gradient1-from to-gradient1-to inline-block text-transparent bg-clip-text',
          )}
        >
          {job}
        </p>
        <p className={concatClassNames('text-center', 'text-gray-400', 'font-circularStd-Light', 'lg:text-lg')}>
          @ {company}
        </p>
      </div>
    </div>
  );
}
export interface RollsProps {
  language: Language;
}

export function Testimonies({ language = Language.FR }: RollsProps): JSX.Element {
  const sliderRef: React.MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <div id="testimonies" className={concatClassNames('flex flex-col gap-2 py-4 text-white pt-20')}>
      <H2Title text="Écoutez les utilisateurs !" color="white" position="center" />
      {/* <h2 className={concatClassNames('text-center font-circularStd-Bold text-xl lg:text-4xl')}>
        {language === Language.ENG ? 'Hear the users!' : 'Écoutez les utilisateurs !'}
      </h2> */}
      <div className={concatClassNames('overflow-hidden')}>
        <Swiper
          slidesPerView={3}
          loop={true}
          centeredSlides={true}
          spaceBetween={30}
          pagination={
            isLargeScreen
              ? {
                  clickable: true,
                  enabled: true,
                }
              : false
          }
          style={{
            '--swiper-pagination-color': '#fff',
          }}
          modules={[Pagination]}
          className="flex h-fit align-middle justify-center items-center w-[250%] -left-[75%] overflow-hidden"
          wrapperClass="flex flex-row mb-14"
          onSwiper={(it: SwiperType) => (sliderRef.current = it)}
        >
          <SwiperSlide>
            <Slide
              name="Benjamin"
              quote={
                language === Language.ENG
                  ? 'Specks brings the ability to gather everyone around our digital ecosystem.'
                  : 'Specks permet de rassembler tous les acteurs autour de notre écosystème digital.'
              }
              linkedin="https://www.linkedin.com/in/benjamin-devillers/"
              job="Product Manager"
              company="Abokine"
              favFeature="The FAQ"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              name="Yannick"
              quote={
                language === Language.ENG
                  ? 'Specks allows for the involvement of all stakeholders in the product.'
                  : "Specks permet l'implication de tous les acteurs dans le produit."
              }
              linkedin="https://www.linkedin.com/in/yannick-graton-70412284/"
              job="VP Engineer"
              company="Nantes Université"
              favFeature=""
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              name="Morgan"
              quote={
                language === Language.ENG
                  ? 'Specks framework for specification enables individuals to access information and communicate using a common language.'
                  : `Le cadre de spécification de Specks permet à tout le monde de retrouver l'information qui l'intéresse tout en parlant le même langage.`
              }
              linkedin="https://www.linkedin.com/in/morgancocherel/"
              job="Product Owner"
              company="SNCF Connect & Tech"
              favFeature="The frontend specification"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              name="Zoé"
              namePath="Zoe"
              quote={
                language === Language.ENG
                  ? 'Specks helps me structure my page before designing it.'
                  : "Specks m'aide à structurer ma page avant de la designer."
              }
              linkedin="https://www.linkedin.com/in/zo%C3%A9-cochet-ux-ui-designer/"
              job="UX/UI Designer"
              company="Com&Médias"
              favFeature="The frontend specification"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              name="Julien"
              quote={
                language === Language.ENG
                  ? 'Modeling dependencies allows us to identify all stakeholders and understand the impacts when something has changed.'
                  : "La modélisation des dépendances nous aide à identifier les impacts lorsqu'on modifie quelque chose."
              }
              linkedin="https://www.linkedin.com/in/julien-sauvard/"
              job="Tech Lead"
              company="GRT Gaz"
              favFeature="The impact assessment"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
