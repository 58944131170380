import React from 'react';
import { type HTitleProps } from './H1Title';
import { textColorStyle, textPositionStyle } from './Blog_Components/ArticleComponents/Text/Text';
import concatClassNames from 'utils/classNames';

export function H3Title({
  text,
  color = 'black',
  position = 'right',
  strikethrough = false,
}: HTitleProps): JSX.Element {
  return (
    <h3
      className={concatClassNames(
        'text-[24px] lg:text-[24px] xl:text-[24px] font-bold',
        textColorStyle[color],
        textPositionStyle[position],
        strikethrough ? 'line-through' : '',
      )}
    >
      {text}
    </h3>
  );
}
