import concatClassNames from 'utils/classNames';
import React from 'react';
import { SpecksHeader } from 'components/Sections/Header/SpecksHeader';
import { Language } from 'utils/languages';
import { Footer } from 'components/Sections/Footer';
import { H1Title } from 'components/H1Title';
import { H2Title } from 'components/H2Title';
import { P } from 'components/P';

export function TermsPage(): JSX.Element {
  return (
    <div
      className={concatClassNames('flex flex-col bg-gradient-to-r from-gradient1-from to-gradient1-to gap-3 lg:gap-2')}
    >
      <SpecksHeader language={Language.FR} />

      <div id="content" className={concatClassNames('flex flex-col', 'gap-4', 'py-6 px-6 lg:px-44')}>
        <H1Title text="MENTIONS LÉGALES" color="white" position="justify" />
        <P
          color="white"
          position="justify"
          text="Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique,
        il est précisé aux utilisateurs du site https://specks.io l'identité des différents intervenants dans le cadre de sa
        réalisation et de son suivi."
        ></P>
        <H2Title text="Edition du site" color="white" position="justify" />
        <P
          color="white"
          position="justify"
          text="Le présent site, accessible à l’URL https://specks.io, est édité par la société SAS Specks au capital de 60000 euros,
        inscrite au R.C.S. de NANTES sous le numéro RCS NANTES B 980 269 062, dont le siège social est situé au 1 RUE
        PAULINE ROLAND 44200 NANTES, est représentée par la société SAS THINK IT RIGHT. Le numéro individuel TVA de
        l’éditeur est : FR95980269062."
        ></P>
        <H2Title color="white" text="Hébergement" position="justify" />
        <P
          color="white"
          position="justify"
          text="Le site est hébergé par la société AWS, située Tour Carpe Diem, 31 Pl. des Corolles, 92400 Courbevoie."
        ></P>
        <H2Title color="white" text="Directeur de publication" position="justify" />
        <P
          position="justify"
          color="white"
          text="La Directrice de la publication du site est la société SAS THINK IT RIGHT."
        ></P>
        <H2Title color="white" text="Nous contacter" position="justify" />
        <P text="Par e-mail : amandine.musseau@specks.io" position="justify" color="white"></P>
      </div>

      <Footer language={Language.FR} textColor="black" />
    </div>
  );
}
