import { textColorStyle, type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import React from 'react';
import concatClassNames from 'utils/classNames';

type ButtonSize = 'p' | 'h1' | 'h2' | 'h3' | 'h4';

const textSizeStyle: Record<ButtonSize, string> = {
  p: 'text-[20px] lg:text-xl',
  h1: 'text-4xl lg:text-6xl',
  h2: 'text-3xl lg:text-5xl',
  h3: 'text-2xl lg:text-4xl',
  h4: 'text-xl lg:text-2xl',
};

type ButtonVerticalPadding = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

type ButtonHorizontalPadding = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

type BackGroundColor = 'pink' | 'purple' | 'gradient' | 'white' | 'blue-300' | 'blue-400';

const backgroundColorStyle: Record<BackGroundColor, string> = {
  pink: 'bg-gradient1-from',
  purple: 'bg-gradient2-to',
  gradient: 'bg-gradient-to-r from-gradient1-from to-gradient1-to',
  white: 'bg-white',
  'blue-300': 'bg-blue-300',
  'blue-400': 'bg-blue-400',
};

const buttonVerticalPaddingStyle: Record<ButtonVerticalPadding, string> = {
  '0': 'py-0 lg:py-0',
  '1': 'py-1 lg:py-2',
  '2': 'py-2 lg:py-4',
  '3': 'py-3 lg:py-6',
  '4': 'py-4 lg:py-8',
  '5': 'py-5 lg:py-10',
  '6': 'py-6 lg:py-12',
  '7': 'py-7 lg:py-14',
  '8': 'py-8 lg:py-16',
  '9': 'py-9 lg:py-18',
  '10': 'py-10 lg:py-20',
};

const buttonHorizontalPaddingStyle: Record<ButtonHorizontalPadding, string> = {
  '0': 'px-0 lg:px-0',
  '1': 'px-1 lg:px-2',
  '2': 'px-2 lg:px-4',
  '3': 'px-3 lg:px-6',
  '4': 'px-4 lg:px-8',
  '5': 'px-5 lg:px-10',
  '6': 'px-6 lg:px-12',
  '7': 'px-7 lg:px-14',
  '8': 'px-8 lg:px-16',
  '9': 'px-9 lg:px-18',
  '10': 'px-10 lg:px-20',
  '11': 'px-11 lg:px-22',
  '12': 'px-12 lg:px-24',
};

interface ButtonProps {
  text: string;
  href: string;
  size?: ButtonSize;
  verticalPadding?: ButtonVerticalPadding;
  horizontalPadding?: ButtonHorizontalPadding;
  backgroundColor?: BackGroundColor;
  textColor?: TextColor;
  borderColor?: string;
  onHover?: string;
  openInNewTab?: boolean;
}

export function Button({
  text,
  href,
  size = 'p',
  verticalPadding = '1',
  horizontalPadding = '1',
  backgroundColor = 'purple',
  textColor = 'black',
  borderColor = '',
  onHover = '',
  openInNewTab = true,
}: ButtonProps): JSX.Element {
  return (
    <a
      type="button"
      className={concatClassNames(
        'justify-center',
        'inline-flex items-center',
        textSizeStyle[size],
        'rounded-lg',

        borderColor,
        onHover,
        buttonVerticalPaddingStyle[verticalPadding],
        buttonHorizontalPaddingStyle[horizontalPadding],
        'font-semibold',
        textColorStyle[textColor],
        'shadow-lg',
        backgroundColorStyle[backgroundColor],
        'w-fit h-fit',
      )}
      href={href}
      target={openInNewTab ? '_blank' : ''}
      rel="noreferrer"
    >
      {text}
    </a>
  );
}
