import React, { useEffect } from 'react';
import concatClassNames from 'utils/classNames';

interface VideoPeopleProps {
  videoPath: string;
  name: string;
  posterPath?: string;
}

export function VideoPeople({ videoPath, name, posterPath }: VideoPeopleProps): JSX.Element {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [myVideo, setMyVideo] = React.useState<any>(null);

  useEffect(() => {
    setMyVideo(document.getElementById('video_' + name));
  }, []);

  function playPause(): void {
    if (myVideo == null) return;
    if (myVideo.paused) {
      myVideo.play();
      setIsPlaying(true);
    } else {
      myVideo.pause();
      setIsPlaying(false);
    }
  }
  return (
    <button
      type="button"
      className={concatClassNames('relative', 'cursor-pointer', 'lg:col-span-3')}
      onClick={playPause}
    >
      {/* {myVideo?.paused && (
        <div
          className={concatClassNames(
            'absolute',
            'top-0 bottom-0 right-0 left-0',
            'flex',
            'items-center',
            'justify-center',
          )}
        >
          {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-24 w-24 text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.5 4.5v11l9-5.5-9-5.5z"
                className={concatClassNames('transition-all', 'duration-500')}
              />
            </svg>
          }
        </div>
      )} */}

      <video id={'video_' + name} controls className="w-full lg:w-full rounded-sm" poster={posterPath} preload="auto">
        <source src={videoPath} type="video/mp4" />
      </video>
    </button>
  );
}
