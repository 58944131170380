import React from 'react';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';

export interface MedleyProps {
  language: Language;
}
export function Medley({ language = Language.FR }: MedleyProps): JSX.Element {
  return (
    <div className={concatClassNames('flex justify-center lg:h-full lg:items-center')}>
      <div
        id="medley"
        className={concatClassNames('', 'm-0', 'rounded-lg', 'bg-white/30 lg:bg-transparent', 'lg:w-[100%]')}
      >
        <img src="/imgs/medleySpecks.png" alt="Specks Demo" className={concatClassNames('lg:h-full')}></img>
      </div>
    </div>
  );
}
