import { H2Title } from 'components/H2Title';
import { P } from 'components/P';
import React from 'react';
import concatClassNames from 'utils/classNames';
import { Audience, Language } from 'utils/languages';
export interface RollProps {
  language: Language;
  audience: Audience;
}
export function Roll({ language = Language.FR, audience = Audience.AGENCIES }: RollProps): JSX.Element {
  return (
    <div className="flex flex-col items-center ">
      {/* {audience === Audience.AGENCIES && (
        <>
          <H2Title text="Specks, le standard de spécification" color="white" />
          { <p className="text-[40px] lg:text-[60px] font-circularStd-Medium text-center text-white pt-10 px-4">
            Specks, le standard de spécification
          </p> }
        </>
      )} */}

      <div
        id="users_types"
        className={concatClassNames(
          'my-16 lg:mt-60 lg:mb-52 text-white lg:flex lg:flex-row lg:justify-center font-circularStd-Medium',
        )}
      >
        <p className={concatClassNames('flex flex-col lg:grid lg:grid-cols-2', 'items-center', 'lg:gap-4')}>
          {audience === Audience.AGENCIES && (
            <>
              <span className={concatClassNames('text-[40px] lg:text-[72px] lg:text-right')}>
                {language === Language.ENG ? 'Designed for' : 'Conçu pour les'}
              </span>
              <div className="changing-text w-full lg:w-[600px]">
                <span className={concatClassNames('text-[40px] lg:text-[72px] text-center lg:text-left lg:-mt-14')}>
                  {language === Language.ENG ? 'Clients' : 'clients'}
                </span>
                <span className={concatClassNames('text-[40px] lg:text-[72px] text-center lg:text-left lg:-mt-14')}>
                  {language === Language.ENG ? 'Project Managers' : 'chefs de projet'}
                </span>
                <span className={concatClassNames('text-[40px] lg:text-[72px] text-center lg:text-left lg:-mt-14')}>
                  {language === Language.ENG ? 'Product Managers' : 'designers'}
                </span>
                <span className={concatClassNames('text-[40px] lg:text-[72px] text-center lg:text-left lg:-mt-14')}>
                  {language === Language.ENG ? 'Developers' : 'développeurs'}
                </span>
                <span className={concatClassNames('text-[40px] lg:text-[72px] text-center lg:text-left lg:-mt-14')}>
                  {language === Language.ENG ? 'Managers' : 'managers'}
                </span>
                <span className={concatClassNames('text-[40px] lg:text-[72px] text-center lg:text-left lg:-mt-14')}>
                  {language === Language.ENG ? 'Q/A Teams' : 'équipes support'}
                </span>
              </div>
              <br />
            </>
          )}
        </p>
      </div>
    </div>
  );
}
