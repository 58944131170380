import { P } from 'components/P';
import { RichText } from 'components/RichText/RichText';
import React, { useEffect, useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Audience, Language } from 'utils/languages';
export interface FeaturesProps {
  language: Language;
  audience: Audience;
}
export function Features({ language = Language.FR, audience = Audience.AGENCIES }: FeaturesProps): JSX.Element {
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const onScroll = (): void => {
      playOnlyCenteredVideo();
    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  function playOnlyCenteredVideo(): void {
    const videos = document.querySelectorAll('.video');

    const mostCenteredVideo = findMostCenteredVideo();
    playVideo(mostCenteredVideo);
    videos.forEach(function (video) {
      if (video !== mostCenteredVideo) {
        pauseVideo(video);
      }
    });

    function findMostCenteredVideo(): Element | null {
      let minDistance = Number.MAX_SAFE_INTEGER;
      let mostCenteredVideo: Element | null = null;

      videos.forEach(function (video) {
        const rect = video.getBoundingClientRect();
        const distance = Math.abs(rect.top - (window.innerHeight * 3) / 10);

        if (distance < minDistance) {
          minDistance = distance;
          mostCenteredVideo = video;
        }
      });
      return mostCenteredVideo;
    }

    function playVideo(video: any): void {
      if (video) {
        const isPlaying: boolean =
          video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;
        if (!isPlaying) {
          const playPromise: Promise<any> = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                // We can now safely pause video...
                // video.pause();
                setIsPlaying(true);
                return null;
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
                console.log(error);
                void error;
              });
          }
        }
      }
    }

    function pauseVideo(video: any): void {
      if (video) {
        if (isPlaying) {
          video.pause();
          setIsPlaying(false);
        }
      }
    }
  }

  return (
    <div
      id="features"
      className={concatClassNames(
        'flex flex-col',
        'relative',
        'font-circularStd-Light',
        'text-gradient1-from',
        'lg:items-center',
        'gap-7 lg:gap-10 px-4 lg:px-20 xl:px-40 pt-16',
      )}
    >
      <div
        className={concatClassNames('text-white hidden lg:text-xl lg:gap-2 lg:flex lg:flex-row font-circularStd-Bold')}
      ></div>
      <P text="FAITES LA DIFFÉRENCE 👇"></P>
      {audience === Audience.AGENCIES ? (
        <>
          <div
            id="dependencies"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="validation_client" autoPlay muted loop className="video w-full lg:w-[50%] shadow-xl">
              <source src="/videos/Features/Specks/Agence validation client.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('flex flex-col gap-2 lg:gap-6 lg:justify-start')}>
              <h3 className={concatClassNames('text-[22px] lg:text-[28px] font-bold text-center lg:text-left', 'mt-3')}>
                {language === Language.ENG
                  ? ''
                  : 'Aidez votre client à prendre des décisions éclairées pour valider un périmètre'}
              </h3>
              {language === Language.ENG ? (
                <p
                  className={concatClassNames(
                    'text-justify',
                    'text-[20px] lg:text-[24px] lg:leading-10',
                    'px-2',
                    'text-black',
                  )}
                >
                  {
                    'Get real-time alerts for technical, cross-team, and external dependencies that may impact your product specifications.'
                  }
                </p>
              ) : (
                <RichText
                  fragments={[
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        'Comment réussir à embarquer votre client, en le faisant valider un périmètre fonctionnel qui vous permet de travailler sereinement, sans entrer dans une négociation contractuelle pénible pour tous ?',
                      size: '2xl',
                    },
                    {
                      contentType: 'br',
                    },
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        "Chez Specks, nous sommes convaincus qu'une meilleure compréhension des perspectives de chacun simplifie considérablement la discussion. Avant même de présenter des maquettes haute-fidélité,  projetez votre client dès les premiers échanges en traduisant son besoin sur une interface visuelle. Il sera plus investi dans cette approche interactive et ludique que dans la lecture de longs paragraphes de compte rendus de réunions. Avec cette spécification standardisée et complète, réduisez le risque d'oublier un élément, une décision fonctionnelle qui pourrait avoir un impact sur le périmètre du projet et vous mettre en difficulté.",
                      size: '2xl',
                    },
                    {
                      contentType: 'br',
                    },
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        'Partagez les règles fonctionnelles avec votre client, aidez le à prendre les meilleures décisions, et gardez la trace de tout ce qui a été acté ensemble, version par version.',
                      size: '2xl',
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div
            id="informations"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="informations_video" autoPlay muted loop className="video w-full lg:w-[50%]  shadow-xl">
              <source src="/videos/Features/Specks/Informations.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('lg:flex lg:flex-col lg:gap-6 lg:justify-start')}>
              <h3
                className={concatClassNames(
                  'text-[22px] lg:text-[28px] font-semibold text-center lg:text-left',
                  'mt-3',
                )}
              >
                {language === Language.ENG
                  ? 'Centralized product information Hub'
                  : 'Centralisez toutes les informations d’un projet au même endroit'}
              </h3>
              {language === Language.ENG ? (
                <p
                  className={concatClassNames(
                    'text-justify',
                    'text-[20px] lg:text-[24px] lg:leading-10',
                    'px-2',
                    'text-black',
                  )}
                >
                  {
                    'Ensure everyone is on the same page by collecting key links, keywords, and resources. A valuable resource for QA and CSM, and useful for onboarding new team members.'
                  }
                </p>
              ) : (
                <RichText
                  fragments={[
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        "Qui n'est jamais arrivé au sein d’un projet commencé il y a des années, avec la difficulté de collecter des informations ici et là, demander des autorisations d'accès à certains documents, sans être parfaitement sûr qu'il s'agit de la dernière version mise à jour? Qui n'a jamais dû appeler son client pour lui demander de répéter une information qu'il a déjà fournie à un collègue?",
                      size: '2xl',
                    },
                    {
                      contentType: 'br',
                    },
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        "Specks vous offre un hub central pour toutes les informations fournies par votre client : définitions d'acronymes, questions fonctionnelles, exemples de l'existant, etc. Rassemblez toutes ces informations à un seul endroit et partagez-le avec qui vous voulez!",
                      size: '2xl',
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div
            id="business_rules"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="business_rules_video" autoPlay muted loop className="video w-full lg:w-[50%] shadow-xl">
              <source src="/videos/Features/Specks/Agence appel API.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('lg:flex lg:flex-col lg:gap-6 lg:justify-start')}>
              <h3
                className={concatClassNames(
                  'text-[22px] lg:text-[28px] font-semibold text-center lg:text-left',
                  'mt-3',
                )}
              >
                {language === Language.ENG
                  ? 'Trace every business rule'
                  : 'Le point de rencontre & de dialogue entre tous les métiers'}
              </h3>
              {language === Language.ENG ? (
                <p
                  className={concatClassNames(
                    'text-justify',
                    'text-[20px] lg:text-[24px] lg:leading-10',
                    'px-2',
                    'text-black',
                  )}
                >
                  {
                    'Specks consolidates all business rules, making it easier to retrieve data for impact studies, user questions, and testing purposes.'
                  }
                </p>
              ) : (
                <RichText
                  fragments={[
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        'Une gestion de projet est réussie lorsque l’on sait trouver le juste équilibre entre le besoin du client et la difficulté technique d’y apporter une réponse. Et il suffit parfois de changer un détail anodin pour simplifier considérablement le travail de développement! Encore faut-il l’identifier, et pour cela réellement comprendre l’argumentaire de chacun. ',
                      size: '2xl',
                    },
                    {
                      contentType: 'br',
                    },
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        'Specks est le point de rencontre entre client, chef de projet et développeur : une interface lisible, dans lequel chacun peut vulgariser son métier à l’ensemble des acteurs du projet. Mettez en évidence les dépendances entre ce qui se passe sur un écran et les implications dans le code, pour engager des discussions le plus tôt possible, garder la trace de vos décisions pour la phase de développement et pour la maintenance du produit.',
                      size: '2xl',
                    },
                  ]}
                />
              )}
            </div>
          </div>

          <div
            id="mapping"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',

              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="mapping_video" autoPlay muted loop className="video w-full lg:w-[50%]  shadow-xl">
              <source src="/videos/Features/Specks/Mapping.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('lg:flex lg:flex-col lg:gap-6 lg:justify-start')}>
              <h3
                className={concatClassNames(
                  'text-[22px] lg:text-[28px] font-semibold text-center lg:text-left',
                  'mt-3',
                )}
              >
                {language === Language.ENG
                  ? 'Streamlined Data Mapping Process'
                  : 'Une approche ludique de la spécification du mapping de données'}
              </h3>

              {language === Language.ENG ? (
                <p
                  className={concatClassNames(
                    'text-justify',
                    'text-[20px] lg:text-[24px] lg:leading-10',
                    'px-2',
                    'text-black',
                  )}
                >
                  {
                    'Accurate requirements are essential for comprehensive data mapping. Specks provides an accessible interface to facilitate collaboration between product and tech teams.'
                  }
                </p>
              ) : (
                <RichText
                  fragments={[
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        "Il arrive fréquemment que les développeurs, manquant d'informations fonctionnelles pour progresser et estimant que celles-ci seront trop complexes à expliquer et à obtenir du client, prennent des décisions par eux-mêmes. Si cette initiative peut être saluée, elle peut néanmoins parfois entraîner des erreurs et donc du reworking lorsque le client découvre ou prend en main la solution.",
                      size: '2xl',
                    },
                    {
                      contentType: 'br',
                    },
                    {
                      contentType: 'p',
                      position: 'justify',
                      content:
                        "Cette situation est courante en matière de mapping de données : lors de la connexion de deux briques applicatives, il faut établir un lien entre des données fonctionnelles ayant des noms techniques, par exemple : rassembler le nom et le prénom de l’applicatif A vers le champ nom de l’applicatif B. Dites adieu au fichier Excel! Specks vous offre une interface ludique pour impliquer votre client, l'aider à prendre des décisions éclairées, et recueillir toutes les informations qu'il a validées en un seul endroit pour l'avenir. Les équipes de support vous en seront reconnaissantes 🙂",
                      size: '2xl',
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="dependencies"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="dependencies_video" autoPlay muted loop className="video w-full lg:w-[50%] shadow-xl">
              <source src="/videos/Features/Specks/Dependencies.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('flex flex-col gap-2 lg:gap-6 lg:justify-start')}>
              <h3 className={concatClassNames('text-[22px] lg:text-[28px] font-bold text-center lg:text-left', 'mt-3')}>
                {language === Language.ENG
                  ? 'Manage dependencies proactively'
                  : 'Gérez les dépendances de manière proactive'}
              </h3>
              {language === Language.ENG ? (
                <p
                  className={concatClassNames(
                    'text-justify',
                    'text-[20px] lg:text-[24px] lg:leading-10',
                    'px-2',
                    'text-black',
                  )}
                >
                  {
                    'Get real-time alerts for technical, cross-team, and external dependencies that may impact your product specifications.'
                  }
                </p>
              ) : (
                <RichText
                  fragments={[
                    {
                      contentType: 'complex',
                      position: 'justify',
                      content: [
                        {
                          contentType: 'span',
                          content:
                            'Recevez des alertes en temps réel pour les dépendances techniques, inter-équipes et externes qui pourraient impacter vos spécifications produit.',
                          size: '2xl',
                        },
                      ],
                    },
                  ]}
                />
              )}
            </div>
          </div>
          <div
            id="informations"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="informations_video" autoPlay muted loop className="video w-full lg:w-[50%]  shadow-xl">
              <source src="/videos/Features/Specks/Informations.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('lg:flex lg:flex-col lg:gap-6 lg:justify-start')}>
              <h3
                className={concatClassNames(
                  'text-[22px] lg:text-[28px] font-semibold text-center lg:text-left',
                  'mt-3',
                )}
              >
                {language === Language.ENG
                  ? 'Centralized product information Hub'
                  : 'Hub d’informations produit centralisé'}
              </h3>
              <p
                className={concatClassNames(
                  'text-justify',
                  'text-[20px] lg:text-[24px] lg:leading-10',
                  'px-2',
                  'text-black',
                )}
              >
                {language === Language.ENG
                  ? 'Ensure everyone is on the same page by collecting key links, keywords, and resources. A valuable resource for QA and CSM, and useful for onboarding new team members.'
                  : 'Assurez-vous que tout le monde est sur la même longueur d’onde en rassemblant des liens clés, des mots-clés et des ressources. Une ressource précieuse pour les équipes de support, maintenance, mais aussi pour toutes les équipes chargées de la relation avec le client. Cette fonctionnalité facilite également l’intégration de nouveaux membres de l’équipe.'}
              </p>
            </div>
          </div>

          <div
            id="business_rules"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="business_rules_video" autoPlay muted loop className="video w-full lg:w-[50%] shadow-xl">
              <source src="/videos/Features/Specks/LogicalRules.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('lg:flex lg:flex-col lg:gap-6 lg:justify-start')}>
              <h3
                className={concatClassNames(
                  'text-[22px] lg:text-[28px] font-semibold text-center lg:text-left',
                  'mt-3',
                )}
              >
                {language === Language.ENG ? 'Trace every business rule' : 'Tracez chaque règle métier'}
              </h3>
              <p
                className={concatClassNames(
                  'text-justify',
                  'text-[20px] lg:text-[24px] lg:leading-10',
                  'px-2',
                  'text-black',
                )}
              >
                {language === Language.ENG
                  ? 'Specks consolidates all business rules, making it easier to retrieve data for impact studies, user questions, and testing purposes.'
                  : `Une question utilisateur ? Besoin de retrouver une information pour une étude d'impact ou vérifier un cas de test ? Specks consolide toutes les règles métier pour vous faciliter le travail. Cet outil de vision globale vous permer de mesurer en un clin d'oeil la complexité de votre applicatif.`}
              </p>
            </div>
          </div>

          <div
            id="mapping"
            className={concatClassNames(
              'flex flex-col lg:flex-row lg:gap-20 items-center',
              'py-6 px-6 gap-4',
              'lg:px-10 lg:py-20',
              'bg-white',
              'rounded-lg',
            )}
          >
            <video id="mapping_video" autoPlay muted loop className="video w-full lg:w-[50%]  shadow-xl">
              <source src="/videos/Features/Specks/Mapping.mp4" type="video/mp4" />
            </video>
            <div className={concatClassNames('lg:flex lg:flex-col lg:gap-6 lg:justify-start')}>
              <h3
                className={concatClassNames(
                  'text-[22px] lg:text-[28px] font-semibold text-center lg:text-left',
                  'mt-3',
                )}
              >
                {language === Language.ENG
                  ? 'Streamlined Data Mapping Process'
                  : 'Processus de mapping de données simplifié'}
              </h3>
              <p
                className={concatClassNames(
                  'text-justify',
                  'text-[20px] lg:text-[24px] lg:leading-10',
                  'px-2',
                  'text-black',
                )}
              >
                {language === Language.ENG
                  ? 'Accurate requirements are essential for comprehensive data mapping. Specks provides an accessible interface to facilitate collaboration between product and tech teams.'
                  : `Lorsque vous réalisez un mapping de données, vous avez besoin d'informations que seul le donneur d'ordre possède. Quoi de plus simple pour l'embarquer qu'une interface ludique ? Specks vous offre une interface visuelle et accessible pour faciliter la collaboration entre les équipe Produit et Tech et augmenter vos chances de récupérer l'ensemble du besoin utilisateur dès le début du projet.`}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
