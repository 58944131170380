import React from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { CardBody } from 'components/Blog_Components/ArticleComponents/Card/CardBody';
import { Text } from 'components/Blog_Components/ArticleComponents/Text/Text';
import { type Article } from './Contenu_Article';
import { CategoryChip } from '../CategoryChip/CategoryChip';
import { CardHeader } from 'components/Blog_Components/ArticleComponents/Card/CardHeader';
import concatClassNames from 'utils/classNames';

interface PageCardProps {
  id?: string;
  article: Article;
  href: string;
}

export function ArticleCard({ id = '', article, href = '' }: PageCardProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  function handleClick(): void {
    navigate(href);
  }
  /* --------------------------------------------------- functions -------------------------------------------------- */

  /* ------------------------------------------------- menu options ------------------------------------------------- */

  return (
    <Card hasAnimation onClick={handleClick} shadow="md" width="md" height="lg" background="gray">
      <CardBody orientation="vertical" justify="center" horizontalPadding="md" gap="md">
        <img className={concatClassNames('w-fit h-[200px] rounded-lg')} src={article.image}></img>
        <Text content={article.title} color="black" weight="bold" size="lg" />
        <CategoryChip category={article.category}></CategoryChip>
      </CardBody>
    </Card>
  );
}
