import React from 'react';
import concatClassNames from 'utils/classNames';
import { textColorStyle, type TextColor, textPositionStyle } from './Blog_Components/ArticleComponents/Text/Text';
import { type HTitleProps } from './H1Title';

export function H2Title({ text, color = 'black', position = 'right' }: HTitleProps): JSX.Element {
  return (
    <h2
      className={concatClassNames(
        'text-[30px] lg:text-[30px] xl:text-[45px] font-circularStd-Bold',
        textColorStyle[color],
        textPositionStyle[position],
      )}
    >
      {text}
    </h2>
  );
}
