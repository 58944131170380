import { Menu, Transition } from '@headlessui/react';
import Specks from 'Specks';
import SpecksText from 'SpecksText';
import { HeaderMenu, type RedirectionProps } from 'components/HeaderMenu/HeaderMenu';
import ArrowUpFromSquareIcon from 'icons/IconsArrowUpFromSquare';
import React, { Fragment, useState } from 'react';
import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';
import { Language } from 'utils/languages';

export interface SpecksHeaderProps {
  language: Language;
  canChangeLanguage?: boolean;
}
export function SpecksHeader({ language = Language.FR, canChangeLanguage = true }: SpecksHeaderProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  let routes: RedirectionProps[];

  if (language === Language.ENG) {
    routes = [
      { text: 'I am a development agency', href: '/fr/agences' },
      { text: 'I am a company', href: '/fr/editeurs' },
      //   { text: 'Try us!', href: '/specks/pre-inscription' },
    ];
  } else {
    routes = [
      { text: 'Fonctionnalités', href: '/#useCases' },
      { text: 'Tarifs', href: '/specks/pricing' },
      { text: 'Essai gratuit', href: '/specks/pre-inscription' },
    ];
  }
  return (
    <div
      className={concatClassNames(
        'flex flex-row',
        'justify-between',
        'sticky top-0',
        'bg-white/30',
        'lg:bg-gradient-to-r lg:from-gradient1-from lg:to-gradient1-to',
        'lg:shadow-lg',
        'backdrop-blur-sm',
        'items-center',
        'py-2',
        'px-3',
        'lg:h-[90px]',
        'z-100',
      )}
    >
      <div
        className={concatClassNames('w-44', 'h-12', 'flex flex-row', 'lg:gap-4', 'items-center', 'cursor-pointer')}
        onClick={() => (window.location.href = '/')}
      >
        <img src="/imgs/logos/Specks.png" alt="Specks Logo" />
      </div>
      <div
        className={concatClassNames(
          'hidden lg:flex flex-row',
          'gap-20',
          'hidden lg:block',
          'text-2xl',
          'text-white',
          'items-center',
        )}
      >
        <a
          href="/#useCases"
          className={concatClassNames(
            'hover:bg-white/20',
            'text-white',
            'py-3 px-6',
            'rounded-2xl',
            'flex flex-row',
            'gap-2',
            'items-center',
          )}
        >
          {language === Language.ENG ? 'Features' : 'Fonctionnalités'}
        </a>
        <a
          href="/specks/pricing/"
          className={concatClassNames(
            'hover:bg-white/20',
            'text-white',
            'py-3 px-6',
            'rounded-2xl',
            'flex flex-row',
            'gap-2',
            'items-center',
          )}
        >
          {'Tarifs'}
        </a>
      </div>
      <div className={concatClassNames('flex flex-row', 'lg:px-3 gap-4', 'items-center')}>
        <a
          type="button"
          className={concatClassNames(
            'hidden lg:inline-flex',
            'justify-center',
            'items-center',
            'text-sm lg:text-lg',
            'rounded-lg',
            'bg-white',
            'py-2 px-4',
            'font-semibold lg:font-circularStd-Regular text-gray-800 hover:text-white',
            'shadow-lg',
            'hover:bg-gradient1-from focus-visible:outline focus-visible:outline-2',
            'focus-visible:outline-offset-2 focus-visible:outline-branbg-branding-600',
            'w-fit h-fit',
          )}
          href={'/specks/pre-inscription'}
          rel="noreferrer"
        >
          {language === Language.ENG ? 'Try us!' : `Essai gratuit`}
        </a>
        <a
          className={concatClassNames('w-5 h-5', 'cursor-pointer')}
          onClick={() => (window.location.href = 'https://app.specks.io')}
        >
          {getIcon('realUser', 'black', 'md')}
        </a>

        <HeaderMenu redirections={routes} />
      </div>
    </div>
  );
}
