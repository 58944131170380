import { type TextColor } from 'components/Blog_Components/ArticleComponents/Text/Text';
import CopyrightIcon from 'icons/IconsCopyright';
import React, { useState } from 'react';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';
export interface FooterProps {
  language: Language;
  textColor?: TextColor;
  discko?: boolean;
}

export function Footer({ language = Language.FR, textColor = 'white', discko = false }: FooterProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  return (
    <div className={concatClassNames('flex flex-col', 'items-center', 'gap-6 lg:gap-4', 'pb-8', 'lg:pt-24')}>
      {!discko && (
        <a
          className={concatClassNames('flex flex-row', 'gap-2', 'items-center', 'mt-6')}
          href="https://fr.linkedin.com/company/specks"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={concatClassNames('w-10 lg:w-12 h-10 lg:h-12 mt-10 lg:mt-0')}
            src="/imgs/linkedin.png"
            alt="linkedin"
          />
        </a>
      )}
      {!discko && (
        <div
          className={concatClassNames(
            'flex flex-row',
            'items-center ',
            textColor,
            'text-base',
            'gap-6',
            'mt-10 lg:mt-0',
          )}
        >
          <a className="underline-offset-4 underline text-center" href="/terms">
            {language === Language.ENG ? 'Legal Information' : 'Mentions légales'}
          </a>
          <a className="underline-offset-4 underline text-center" href="/">
            {language === Language.ENG ? 'Specks' : 'Specks'}
          </a>
          <a
            className="underline-offset-4 underline text-center"
            href="https://discko.io"
            target="_blank"
            rel="noreferrer"
          >
            {language === Language.ENG ? 'Discko' : 'Discko'}
          </a>
          <a className="underline-offset-4 underline text-center" href="https://calendly.com/amandine-musseau/30">
            {language === Language.ENG ? 'Contact us' : 'Nous rencontrer'}
          </a>
        </div>
      )}
      <div
        className={concatClassNames('grid grid-cols-5', 'items-center justify-center', 'gap-2', 'p-2', 'bg-white/30')}
      >
        <img
          className={concatClassNames('lg:w-[30%]', 'mx-auto')}
          src="/imgs/footer/chambre_de_commerce.png"
          alt="chambre_de_commerce"
        />
        <img className={concatClassNames('lg:w-[30%]', 'mx-auto')} src="/imgs/footer/ici_lundi.png" alt="linkedin" />
        <img
          className={concatClassNames('lg:w-[30%]', 'mx-auto')}
          src="/imgs/footer/region_pays_de_la_loire.png"
          alt="region_pays_de_la_loire"
        />
        <img
          className={concatClassNames('lg:w-[30%]', 'mx-auto')}
          src="/imgs/footer/reseau_entreprendre.png"
          alt="reseau_entreprendre"
        />
        <img
          className={concatClassNames('lg:w-[30%]', 'mx-auto')}
          src="/imgs/footer/adn_booster.png"
          alt="adn_booster"
        />
      </div>
      <div className={concatClassNames('flex', 'gap-1', 'items-center')}>
        <CopyrightIcon color="black" size="sm" />
        <p className={concatClassNames(textColor)}>2024 Specks. All rights reserved</p>
      </div>
    </div>
  );
}
