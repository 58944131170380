import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import { BlogHomePage } from 'pages/Blog/BlogHomePage';
import { FrHomePage } from 'pages/HomePage/FR-HomePage';
import { EngHomePage } from 'pages/HomePage/ENG-HomePage';
import { SpecksInscriptionPage } from 'pages/HomePage/FR-SpecksInscription';
import { TermsPage } from 'pages/Terms/Terms';
import { PricingPage } from 'pages/Pricing/PricingPage';

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<FrHomePage />} />
          <Route path="/fr" element={<FrHomePage />} />
          {/* <Route path="/fr/agences" element={<FrAgencies />} /> */}
          {/* <Route path="/fr/editeurs" element={<CompaniesPage />} /> */}
          <Route path="/eng" element={<EngHomePage />} />
          <Route path="/specks/pre-inscription" element={<SpecksInscriptionPage />} />
          <Route path="/blog" element={<BlogHomePage />} />
          <Route path="/specks/pricing" element={<PricingPage />} />

          {/* <Route path="/features/mapping" element={<MappingPage />} />
          <Route path="/features/dependencies" element={<DependenciesPage />} />
          <Route path="/features/informations" element={<InformationsPage />} />
          <Route path="/features/business-rules" element={<BusinessRulesPage />} /> */}
          {/* <Route path="/contact" element={<ContactPage />} /> */}
          <Route path="/terms" element={<TermsPage />} />
        </Routes>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
