import { Card } from 'components/Blog_Components/ArticleComponents/Card/Card';
import { H2Title } from 'components/H2Title';
import { H3Title } from 'components/H3Title';
import { P } from 'components/P';
import React from 'react';
import getIcon from 'utils/getIcon';
import { Audience, Language } from 'utils/languages';

interface TrialProcessProps {
  language: Language;
  audience: Audience;
}

export function TrialProcess({ language = Language.FR, audience = Audience.AGENCIES }: TrialProcessProps): JSX.Element {
  return (
    <div className="flex flex-col gap-10 items-center align-middle mt-12 lg:mt-0">
      <H2Title text="Comment profiter de la version d’essai ?" color="white" position="center" />
      <div className="flex lg:flex-row flex-col items-center align-items justify-between gap-8 px-10">
        <Card width="md" height="md" background="white" textColor="black" horizontalPadding="lg">
          <div className="flex flex-col align-middle items-center gap-8 ">
            <div className="flex flex-row gap-4 items-center">
              <div className=" flex rounded-full bg-white border-2 border-black h-8 w-8 items-center align-middle justify-center drop-shadow-lg">
                <p className="text-black font-circularStd-Bold drop-shadow-lg">1</p>
              </div>
              <H3Title text="Inscrivez-vous"></H3Title>
            </div>
            <P
              position="justify"
              text="✍️ Complétez un formulaire rapide pour que notre équipe crée votre compte, c'est gratuit et sans engagement!"
            ></P>
          </div>
        </Card>
        <Card width="md" height="md" background="white" textColor="black" horizontalPadding="lg" verticalPadding="md">
          <div className="flex flex-col align-middle  items-center gap-8">
            <div className="flex flex-row gap-4 items-center">
              <div className=" flex rounded-full bg-white border-2 border-black h-8 w-8 items-center align-middle justify-center drop-shadow-lg">
                <p className="text-black font-circularStd-Bold drop-shadow-lg">2</p>
              </div>
              <H3Title text="Connectez-vous"></H3Title>
            </div>
            <P
              position="justify"
              text="👉 Recevez vos identifiants de connexion pour commencer à utiliser notre SaaS en toute simplicité"
            ></P>
          </div>
        </Card>
        <Card width="md" height="md" background="white" textColor="black" horizontalPadding="lg">
          <div className="flex flex-col align-middle items-center gap-8 ">
            <div className="flex flex-row gap-4 items-center">
              <div className=" flex rounded-full bg-white border-2 border-black h-8 w-8 items-center align-middle justify-center drop-shadow-lg">
                <p className="text-black font-circularStd-Bold drop-shadow-lg">3</p>
              </div>
              <H3Title text="Explorez 🚀"></H3Title>
            </div>
            <P
              position="justify"
              text="✨Découvrez toutes les fonctionnalités de Specks pendant deux semaines sans aucun engagement"
            ></P>
          </div>
        </Card>
      </div>
    </div>
  );
}
