import React from 'react';
import { type Article, articles, type Categories } from './Contenu_Article';
import concatClassNames from 'utils/classNames';
import { CategoryChip } from '../CategoryChip/CategoryChip';
import { ArticleCard } from './ArticleCard';
import uuid from 'react-uuid';

interface ArticlesPreviewProps {
  categories: Categories[];
  previewInArticle?: boolean;
}

export function ArticlesPreview({ categories, previewInArticle = false }: ArticlesPreviewProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-col first-letter px-36 pb-36  items-start ',
        previewInArticle ? 'gap-6' : 'gap-10',
      )}
    >
      <h2
        className={concatClassNames(
          'flex flex-col first-letter items-start font-circularStd-Bold tracking-wide text-3xl text-black',
          previewInArticle ? 'text-xl' : 'text-3xl',
        )}
      >
        {previewInArticle ? 'SUJETS CONNEXES' : 'NOS ARTICLES'}
      </h2>
      {!previewInArticle && (
        <div className={concatClassNames('flex flex-row gap-3')}>
          <CategoryChip category="tech" />
          <CategoryChip category="product" />
        </div>
      )}
      <div className={concatClassNames('grid grid-cols-5 items-start justify-start gap-10 w-full ')}>
        {articles.map((article: Article) => (
          <>
            <ArticleCard key={uuid()} article={article} href={`/blog/${article.category}/${article.urlName}`} />
            {/* <ArticlePage key={uuid()} article={article} /> */}
          </>
        ))}
      </div>
    </div>
  );
}
