import * as React from 'react';
import { useState } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';

const tickVariants = {
  checked: { pathLength: 1 },
  unchecked: { pathLength: 0 },
};

const boxVariants = {
  checked: { stroke: '#FFFFFF' },
  unchecked: { stroke: '#ddd', strokeWidth: 50 },
};

interface CheckBoxProps {
  delay: number;
}

export function CheckBox({ delay }: CheckBoxProps): JSX.Element {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  return (
    <motion.svg
      initial="unchecked"
      // animate={isChecked ? 'checked' : 'unchecked'}
      whileInView="checked"
      width={isLargeScreen ? '35%' : '60%'}
      viewBox="0 0 440 440"
      transition={{ delay, duration: 0.7, repeat: Infinity, repeatDelay: 3 }}
      viewport={{ once: true }}
    >
      <motion.path
        d="M 72 136 C 72 100.654 100.654 72 136 72 L 304 72 C 339.346 72 368 100.654 368 136 L 368 304 C 368 339.346 339.346 368 304 368 L 136 368 C 100.654 368 72 339.346 72 304 Z"
        fill="#FFFFFF"
        strokeWidth="50"
        stroke="#FFFFFF"
        variants={boxVariants}
        transition={{ delay, duration: 0.7, repeat: Infinity, repeatDelay: 3 }}
        viewport={{ once: true }}
      />
      <motion.path
        d="M 0 128.666 L 128.658 257.373 L 341.808 0"
        transform="translate(54.917 88.332) rotate(-4 170.904 128.687)"
        fill="transparent"
        strokeWidth="50"
        stroke="#07bc0c"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={tickVariants}
        style={{ pathLength, opacity }}
        transition={{ delay, duration: 0.7, repeat: Infinity, repeatDelay: 3 }}
        viewport={{ once: true }}
      />
      <motion.path
        d="M 0 128.666 L 128.658 257.373 L 341.808 0"
        transform="translate(54.917 68.947) rotate(-4 170.904 128.687)"
        fill="transparent"
        strokeWidth="50"
        stroke="#07bc0c"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={tickVariants}
        style={{ pathLength, opacity }}
        transition={{ delay, duration: 0.7, repeat: Infinity, repeatDelay: 3 }}
        viewport={{ once: true }}
      />
    </motion.svg>
  );
}
