import { CheckBox } from 'icons/CheckBox';
import React from 'react';
import concatClassNames from 'utils/classNames';
import { Audience, Language } from 'utils/languages';

interface GridItemProps {
  text: string;
  icon: JSX.Element;
  delay: number;
}

export interface ValueProposalProps {
  language: Language;
  audience: Audience;
}
function GridItem({ text, icon, delay }: GridItemProps): JSX.Element {
  return (
    <div className={concatClassNames('grid grid-cols-5 lg:justify-center lg:items-center')}>
      <div className={concatClassNames('col-span-1 h-fit', 'flex justify-center')}>
        <CheckBox delay={delay} />
      </div>
      <p
        className={concatClassNames(
          'col-start-2 col-end-6',
          'font-circularStd-Regular text-xl lg:text-[22px] lg:leading-relaxed h-fit',
        )}
      >
        {text}
      </p>
    </div>
  );
}

export function ValueProposal({
  language = Language.FR,
  audience = Audience.AGENCIES,
}: ValueProposalProps): JSX.Element {
  return (
    <div className={concatClassNames(' lg:flex lg:justify-center')}>
      {audience === Audience.AGENCIES && (
        <div
          className={concatClassNames(
            'grid grid-cols-1 lg:grid-cols-2 lg:mx-20 xl:mx-40 lg:bg-white/30 lg:rounded-md px-4 py-10 lg:px-10 lg:py-16 text-white gap-2 lg:gap-6 bg-transparent border- border-white/80  ',
          )}
        >
          <GridItem
            text={
              language === Language.ENG
                ? 'Secure your margins.'
                : 'Réalisez des estimations plus fiables et sécurisez vos marges'
            }
            icon={<div> </div>}
            delay={0}
          />
          <GridItem
            text={
              language === Language.ENG
                ? 'Homogenize deliverables.'
                : 'Remettez à vos clients des spécifications visuelles et standardisées'
            }
            icon={<div> </div>}
            delay={0.8}
          />
          <GridItem
            text={
              language === Language.ENG
                ? 'Ensure the security of knowledge during departures.'
                : `Incluez votre client dans les décisions et validez en toute facilité le périmètre de vos projets`
            }
            icon={<div> </div>}
            delay={1.6}
          />
          <GridItem
            text={
              language === Language.ENG
                ? 'Foster closer collaboration between Product & Tech teams.'
                : 'Accélérez les développements grâce à des spécifications complètes et précises'
            }
            icon={<div> </div>}
            delay={2.4}
          />
        </div>
      )}
      {audience === Audience.ENTEPRISES && (
        <div
          className={concatClassNames(
            'grid grid-cols-1 lg:grid-cols-2 lg:mx-20 xl:mx-40 lg:bg-white/30 lg:rounded-md px-4 py-10 lg:px-10 lg:py-16 text-white gap-2 lg:gap-6 bg-transparent border- border-white/80  ',
          )}
        >
          <GridItem
            text={
              language === Language.ENG
                ? 'Secure your margins.'
                : 'Accélérez les développements grâce à des spécifications complètes et précises.'
            }
            icon={<div> </div>}
            delay={0}
          />
          <GridItem
            text={
              language === Language.ENG ? 'Homogenize deliverables.' : "Homogénéisez l'ensemble de vos spécifications"
            }
            icon={<div> </div>}
            delay={0.8}
          />
          <GridItem
            text={
              language === Language.ENG
                ? 'Ensure the security of knowledge during departures.'
                : `Sécurisez la connaissance de vos produits pour mieux prévenir les absences et départs`
            }
            icon={<div> </div>}
            delay={1.6}
          />
          <GridItem
            text={
              language === Language.ENG
                ? 'Foster closer collaboration between Product & Tech teams.'
                : 'Favorisez la collaboration entre les équipes Marketing, Produit et Tech pour des développements plus fluides'
            }
            icon={<div> </div>}
            delay={2.4}
          />
        </div>
      )}
    </div>
  );
}
