import React from 'react';

import concatClassNames from 'utils/classNames';
import { Chip, type ChipBorderColor } from '../Chip/Chip';
import { type ChipBgColor } from '../Chip/Chip.props';
import { type TextSize } from 'components/Blog_Components/ArticleComponents/Text/Text';

interface CategoryTypeChipProps {
  category: string;
  isFunctional?: boolean;
  size?: TextSize;
}

interface CategoryTypeChipValue {
  text: string;
  color: string;
}

const typeToValue: Record<string, CategoryTypeChipValue> = {
  tech: { text: 'TECH & AI', color: 'miscelleanous-blue' },
  product: { text: 'PRODUIT', color: 'green-500' },
};

export function CategoryChip({ category, isFunctional, size = 'api' }: CategoryTypeChipProps): JSX.Element {
  return (
    <Chip
      label={typeToValue[category].text}
      textColor="white"
      textSize={size}
      bgColor={typeToValue[category].color as ChipBgColor}
      textTracking="wide"
      paddingTop="md"
      paddingHorizontal="md"
      borderColor={typeToValue[category].color as ChipBorderColor}
    />
  );
}
