import React from 'react';
import concatClassNames from 'utils/classNames';
import { Button } from 'components/Button/Button';
import { Language } from 'utils/languages';

export interface CTAProps {
  language: Language;
}

export function CTA({ language = Language.FR }: CTAProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'flex flex-col',
        'text-white',
        'text-center',
        'items-center',
        'font-semibold',
        'p-3',
        '',
        'pb-32',
        'pt-28',
        'gap-14 lg:gap-10',
        'text-[40px] lg:text-[40px]',
      )}
    >
      <div>
        <p>Vital pour demain.</p>
        <p>{"Disponible aujourd'hui."}</p>
      </div>
      <div className={concatClassNames('flex', 'justify-end')}>
        <Button
          text="Demandez un accès gratuit!"
          href="/specks/pre-inscription"
          backgroundColor="white"
          horizontalPadding="3"
          verticalPadding="2"
          onHover="hover:bg-gradient1-from focus-visible:outline focus-visible:outline-2 hover:text-white"
        />
      </div>
    </div>
  );
}
