/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function UserIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={concatClassNames(iconSizeStyle[size])} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <path
        stroke={iconColorStyle[color]}
        d="M102.72,34.68a4,4,0,0,0-1.49-.8,10.07,10.07,0,0,0-1.76-.44,26.36,26.36,0,0,0-3.69-.35,53,53,0,0,0-7.46.35c-.4.07-.6-2.57-.16-2.55a54.51,54.51,0,0,1,7.77.5,27,27,0,0,1,3.84.8,12.55,12.55,0,0,1,1.88.69,5.06,5.06,0,0,1,1.7,1.21C103.78,34.63,103.05,35,102.72,34.68Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M24.42,73.75l-.56-14-.25-5.11c-.19-6-.32-12-.43-18,0-2.66-.15-5.34-.24-8A61.08,61.08,0,0,1,23,18.44c.13-1.06.44-.92.89,0a17.43,17.43,0,0,1,.75,1.84,10.79,10.79,0,0,1,.43,2.63c.28,6.91.42,13.82.41,20.81,0,4.17.29,8.51.46,12.78l.59,14.08L27,79.23c.24,4.37.46,8.77.78,13.1.26,3.37.65,6.67,1,10,.09.73.17,1.44.25,2.18.18,2.74.08,3.62-.44,4.25-.11.15-.2.34-.29.43-.26.34-.93-.79-1.24-2.78-1-5.93-1.41-12.22-1.86-18.53-.32-4.69-.56-9.41-.81-14.11Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M99.83,82.76c1-12.26,2-24.52,2.82-36.77.14-2.23.25-4.47.32-6.69,0-1.11.07-2.23.07-3.32,0-.55,0-1.09,0-1.62,0-.26,0-.54,0-.73a.44.44,0,0,0,0-.21l-.1-.09-.21-.12a5.48,5.48,0,0,0-.81-.34,12.41,12.41,0,0,0-1.91-.48A34.9,34.9,0,0,0,95.77,32c-1.41-.07-2.83-.09-4.26-.07l-2.14.06c-1.06,0-2.4.14-3.57.22l.25-1.88.1-.93.08-.79.17-2.2c.1-1.46.17-2.93.22-4.4s.09-2.95.09-4.41l0-1.09,0-.94a3.59,3.59,0,0,0,0-.46c0-.21-.22-.14-.36-.15h-.32l-.72,0L83.68,15l-3.06.18-6.14.37c-7,.38-14.09.7-21.08.68a118,118,0,0,1-13.53-.67,49,49,0,0,1-6.74-1.25,15.67,15.67,0,0,1-6.38-3.1,6.57,6.57,0,0,1-.7-.74,5.21,5.21,0,0,1-.34-.63c-.15-.35-.14-.53,0-.57s.37,0,.69.21l.52.31.72.33A19.54,19.54,0,0,0,32,11.52c1.89.38,4.14.72,6.61,1.08a104.85,104.85,0,0,0,11.82.91c4,.11,8,.09,12,0s8-.26,12-.46l6-.33,3-.17,1.52-.07.83,0,1.37,0A1.89,1.89,0,0,1,89,14a14,14,0,0,1,.15,1.45l0,.86c0,1.13,0,2.24,0,3.35,0,1.87-.14,3.73-.25,5.62-.06.94-.13,1.89-.19,2.84l-.09,1-.06.44,1.38-.06c1,0,1.89-.06,2.85-.06a53.08,53.08,0,0,1,5.75.24,23.18,23.18,0,0,1,2.93.47,10.62,10.62,0,0,1,1.5.44,5.74,5.74,0,0,1,1.64.87,5.32,5.32,0,0,1,.45.38l.21.2c.08.06.06.18.09.27a6.73,6.73,0,0,1,.19,1.12c0,.52.06,1,.07,1.49,0,1,0,1.92,0,2.87-.21,6.24-.68,12.41-1.12,18.6L103,74.93c-.6,7.61-1.21,15.21-1.72,22.81-.19,2.87-.38,5.75-.53,8.63-.07,1.44-.14,2.88-.18,4.31l0,1.08v1.86c0,.06-.09.09-.13.13l-.62.48a3.59,3.59,0,0,1-.63.39c-.18.09-.36.2-.54.27-2.92,1.19-5.87,1.3-8.78,1.45s-5.81,0-8.68-.13c-4.48-.24-8.88-.62-13.27-1l-6.56-.66c-1.08-.11-2.2-.23-3.22-.27s-2.15,0-3.21.08c-1.91.1-3.76.29-5.65.55-3.5.51-5.75,1.12-7.37,1.47a11.93,11.93,0,0,1-3.41.43,7.25,7.25,0,0,1-1.13-.07c-.42-.12,0-.55,1.2-1.2a28,28,0,0,1,5.78-2.16,53.22,53.22,0,0,1,12.11-1.65c.52,0,1,0,1.57,0s1.1.06,1.63.1l3.06.3,6.09.63c4.06.41,8.15.78,12.24,1a78.34,78.34,0,0,0,12.17-.11,17.32,17.32,0,0,0,4.08-.88l.43-.17.18-.1.12-.07h0v-.43l0-1.19c.08-3.14.26-6.25.45-9.36.39-6.21.85-12.41,1.32-18.6Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M105.49,32.36c-3.63-7-10.11-14.18-16.33-19-.54-.42-2.77.63-2.47.86a61.07,61.07,0,0,1,16.2,18.8c.3.59,2.78-.36,2.6-.7Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M37.78,43a4.54,4.54,0,0,1,1.62-.83A4.78,4.78,0,0,1,41,42c3.52.06,7.09,0,10.65-.08l9.19,0c5.28-.08,10.56-.24,15.84-.38l4.68-.12c1.49-.06,3-.12,4.49-.15a3.21,3.21,0,0,1,.76,0,.66.66,0,0,1,.32,1.19c-.11.11-.23.27-.34.28-1.16,0-2.07.84-3.29.85-2.26,0-4.52.23-6.77.35-.91,0-1.81,0-2.72,0l-3.47-.12c-1.58,0-9,.09-16.15.11-3.57,0-7.06,0-9.71-.1-1.33,0-2.45-.1-3.25-.13s-1.31-.13-1.4-.14A13.2,13.2,0,0,1,37.78,43Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M39.6,57A4.29,4.29,0,0,1,41,56.08a3.54,3.54,0,0,1,1.34-.23c3-.07,6-.25,9.06-.37,2.61-.11,5.23-.1,7.84-.18,4.5-.13,9-.27,13.54-.26l4,.07c1.29,0,2.57,0,3.87.13a2.32,2.32,0,0,1,.65.06.68.68,0,0,1,.16,1.21c-.1.1-.21.26-.31.25-1,0-1.82.67-2.85.63a54.85,54.85,0,0,0-5.73.06c-.76,0-1.53,0-2.3,0l-2.94-.18c-1.34-.05-7.65,0-13.72.2-3,.08-6,.14-8.28.14a39.12,39.12,0,0,1-4-.12A9.65,9.65,0,0,1,39.6,57Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M40.85,71.61a4.66,4.66,0,0,1,1.48-.87,4.4,4.4,0,0,1,1.42-.23c3.19-.05,6.41-.23,9.63-.34,2.77-.09,5.55-.07,8.33-.14,4.78-.11,9.57-.24,14.37-.23,1.42,0,2.84.06,4.26.07s2.73,0,4.11.14a2.61,2.61,0,0,1,.69.06.68.68,0,0,1,.18,1.22c-.11.09-.23.25-.33.24-1,0-1.92.67-3,.63a58.94,58.94,0,0,0-6.09,0c-.82,0-1.63,0-2.45,0L70.31,72c-1.43,0-8.14,0-14.59.16-3.22.06-6.38.11-8.78.1a42.09,42.09,0,0,1-4.22-.13A10.18,10.18,0,0,1,40.85,71.61Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M51.5,87c.54-1,.88-1.18,1.27-1.2,1.26,0,2.55-.13,3.83-.16,1.11,0,2.22.06,3.33,0,1.91,0,3.82-.09,5.74-.15.56,0,1.13,0,1.69-.06s1.08-.1,1.62-.12c.09,0,.19-.06.27,0,.24.16.31.75.15,1.2,0,.1-.08.27-.12.27-.42,0-.74.84-1.18.85a17.11,17.11,0,0,0-2.46.26,4.68,4.68,0,0,1-1,0c-.42,0-.84-.14-1.27-.17s-3.28,0-5.87-.09c-1.3,0-2.58-.06-3.55-.11a12.37,12.37,0,0,1-1.72-.15A3.32,3.32,0,0,1,51.5,87Z"
      />
      <path
        stroke={iconColorStyle[color]}
        d="M43.41,114.89a63.52,63.52,0,0,1,26.09-.54c14.65,2.68,30.23-1.09,30.23-1.09s-3.67,8.72-28.82,4.19c-14.47-2.6-25.88,1.46-28.45,1.46C41,118.91,43.41,114.89,43.41,114.89Z"
      />
    </svg>
  );
}
