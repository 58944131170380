import React, { useEffect } from 'react';
import './style.css';
import concatClassNames from 'utils/classNames';
import { Language } from 'utils/languages';
import { H3Title } from 'components/H3Title';
import { H2Title } from 'components/H2Title';
export interface SliderPartnersProps {
  language: Language;
}
export function SliderPartners({ language = Language.FR }: SliderPartnersProps): JSX.Element {
  useEffect(() => {
    const container: Element | null = document.querySelector(`.logos`);
    const slide: Element | null = document.querySelector(`.logos-slide`);

    if (!container || !slide) return;
    if (container.childElementCount >= 3) return;
    const cloneSlide: Node = slide.cloneNode(true);
    container.appendChild(cloneSlide);
    container.appendChild(cloneSlide);
  }, []);

  return (
    <>
      <H2Title text="Ils nous font confiance 💫" color="white" position="center" weight="bold" />
      <div id="partners" className={concatClassNames('logos', 'bg-white', 'mt-5')}>
        <div className="logos-slide w-[2000px]">
          <img className="h-[100px]" src="/imgs/partners/Artishoc.png" />
          <img className="h-[45px]" src="/imgs/partners/Dashdoc.png" />
          <img className="h-[70px]" src="/imgs/partners/Digitaleo.png" />
          <img className="h-[50px]" src="/imgs/partners/Yamuti.png" />
          <img className="h-[70px]" src="/imgs/partners/Koesio.png" />
        </div>
      </div>
    </>
  );
}
