import React, { useRef, useState } from 'react';
import { type Swiper as SwiperType } from 'swiper';
import { Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import concatClassNames from 'utils/classNames';
// import './scrollbar.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './stylePagination.css';
import { Language } from 'utils/languages';
import { RichText, type TextProps } from 'components/RichText/RichText';

interface SpecificationProps {
  title: string;
  subtitle?: string;
  description: string | TextProps[];
  path: string;
  reversed?: boolean;
}

function Specification({ title, subtitle, description, path, reversed = false }: SpecificationProps): JSX.Element {
  return (
    <div
      className={concatClassNames(
        'grid auto-row-max lg:grid-cols-4 items-center lg:justify-center rounded-2 gap-8 h-full',
      )}
    >
      <div
        className={concatClassNames(
          'lg:hidden text-[22px] text-center flex flex-row font-circularStd-Medium h-fit',
          'justify-center',
        )}
      >
        <h1>{title}</h1>
      </div>

      {reversed && (
        <div className={concatClassNames('lg:col-span-2 lg:flex lg:flex-col lg:gap-8 lg:mr-20')}>
          <h1 className="hidden lg:block lg:text-[28px] font-circularStd-Medium">{title}</h1>
          <h2 className="hidden lg:block lg:text-[28px] font-circularStd-Medium">{subtitle}</h2>

          {typeof description === 'string' ? (
            <p
              className={concatClassNames(
                'text-center lg:text-justify',
                'px-1',
                'text-white text-[16px] lg:leading-10  lg:text-[28px]',
              )}
            >
              {description}
            </p>
          ) : (
            <RichText fragments={description} />
          )}
        </div>
      )}
      <video
        id="myVideo"
        autoPlay
        muted
        loop
        className={concatClassNames('w-full lg:col-span-2')}
        preload="auto"
        poster={`/imgs/thumbnails/${path}.png`}
      >
        <source src={`/videos/Features/Specks/${path}.mp4`} type="video/mp4" />
      </video>
      {!reversed && (
        <div className={concatClassNames('lg:col-span-2 lg:flex lg:flex-col lg:gap-4 lg:ml-20')}>
          <h1 className="hidden lg:block lg:text-[28px] font-circularStd-Medium">{title}</h1>
          <h2 className="hidden lg:block lg:text-[28px] font-circularStd-Medium">{subtitle}</h2>

          {typeof description === 'string' ? (
            <p
              className={concatClassNames(
                'text-center lg:text-justify',
                'px-1',
                'text-white text-[16px] lg:leading-10 lg:text-[28px]',
              )}
            >
              {description}
            </p>
          ) : (
            <RichText fragments={description} />
          )}
        </div>
      )}
    </div>
  );
}
export interface SpecificationTabsProps {
  language: Language;
}
export function SpecificationTabs({ language = Language.FR }: SpecificationTabsProps): JSX.Element {
  const sliderRef: React.MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    }
  }, [window.innerWidth]);

  function getContent(isLargeScreen: boolean = true): JSX.Element {
    return (
      <>
        <SwiperSlide>
          <Specification
            title={language === Language.ENG ? 'FOR BOTH FRONT-END...' : 'DU FRONT-END AU BACK-END '}
            subtitle={
              language === Language.ENG
                ? ''
                : 'Ecrivez les règles métier pour faciliter le travail du designer et du développeur'
            }
            description={
              language === Language.ENG
                ? 'The intuitive user interface simplifies the creation of new front-end applications and gathers your business rules that were previously lost in tickets.'
                : [
                    {
                      contentType: 'complex',
                      content: [
                        {
                          contentType: 'span',
                          content: 'Pour développer une application front-end,',
                        },
                        {
                          contentType: 'wbr',
                        },
                        {
                          contentType: 'span',
                          content:
                            ' il faut définir des écrans. Specks vous permet de consolider toutes les informations reçues lors des échanges avec votre client, indépendamment des choix du designer. Son interface utilisateur intuitive vous guide afin de n’oublier aucune question lors de vos ateliers client, et offre une visualisation rapide de toutes les règles définies et validées par votre client.',
                        },
                      ],
                      //   content:
                      //     'Pour développer une application front-end, il faut définir des écrans. Specks vous permet de consolider toutes les informations reçues lors des échanges avec votre client, indépendamment des choix du designer. Son interface utilisateur intuitive vous guide afin de n’oublier aucune question lors de vos ateliers client, et offre une visualisation rapide de toutes les règles définies et validées par votre client.',
                      color: 'white',
                      size: '2xl',
                      position: 'justify',
                    },
                    {
                      contentType: 'p',
                      content:
                        'Specks s’inscrit en complément des maquettes high-fidelity en centralisant les données métier sur le temps long, sans nécessiter de mises à jour à chaque changement de couleur ou d’emplacement.',
                      color: 'white',
                      size: '2xl',
                      position: 'justify',
                    },
                  ]
            }
            path="Spec-front"
            reversed={isLargeScreen}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Specification
            title={language === Language.ENG ? '... & BACK-END SPECIFICATION' : 'DU FRONT-END AU BACK-END '}
            subtitle={
              language === Language.ENG
                ? ''
                : "Entraînez vos développeurs à définir les grandes étapes d'une manière lisible par tous"
            }
            description={
              language === Language.ENG
                ? 'Organize your development work, share best development practices and maintain control over your application portfolio.'
                : [
                    {
                      contentType: 'p',
                      content:
                        'Un projet réussi est un projet dans lequel vous réussissez à engager tous les acteurs, du client au développeur. La clef du succès réside dans un développeur qui sait vulgariser son travail, ses attentes et ses contraintes, de façon à ce que vous puissiez aider votre client à prendre des décisions éclairées.',
                      color: 'white',
                      size: '2xl',
                      position: 'justify',
                    },
                    {
                      contentType: 'p',
                      content:
                        "Specks offre une interface intuitive et facile à mettre à jour, pour que les développeurs puissent définir et séquencer leur travail d'une manière lisible par tous.",
                      color: 'white',
                      size: '2xl',
                      position: 'justify',
                    },
                    {
                      contentType: 'p',
                      content:
                        "Organisez votre travail de développement, assurez la diffusion des meilleures pratiques de développement à tous les développeurs de votre équipe et gardez le contrôle sur votre portefeuille d'applications.",
                      color: 'white',
                      size: '2xl',
                      position: 'justify',
                    },
                  ]
            }
            path="Spec-API"
          />
        </SwiperSlide>
      </>
    );
  }

  return (
    <div id="product" className={concatClassNames('pt-16 px-4 lg:px-20 xl:px-40', 'text-white ')}>
      {isLargeScreen && (
        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          slideToClickedSlide={true}
          spaceBetween={20}
          pagination={{
            clickable: true,
            enabled: true,
          }}
          style={{
            '--swiper-pagination-color': '#fff',
          }}
          modules={[Pagination]}
          className="flex h-fit pb-7 mb-7"
          wrapperClass="flex flex-row mb-14"
          onSwiper={(it: SwiperType) => (sliderRef.current = it)}
        >
          {getContent()}
        </Swiper>
      )}
      {!isLargeScreen && (
        <Swiper
          slidesPerView={1}
          centeredSlides={true}
          slideToClickedSlide={true}
          scrollbar={{
            enabled: true,
            hide: false,
          }}
          spaceBetween={20}
          modules={[Scrollbar]}
          className="flex h-fit pb-7 mb-7"
          wrapperClass="flex flex-row"
          onSwiper={(it: SwiperType) => (sliderRef.current = it)}
        >
          {getContent(false)}
        </Swiper>
      )}
    </div>
  );
}
