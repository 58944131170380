export interface Article {
  urlName: string;
  category: Categories;
  title: string;
  image: string;
  subtitle?: string;
  paragraphs: Array<{ title: string; subparagraphs: Array<{ title: string; content?: string; list?: string[] }> }>;
  conclusion: string;
}
export enum Categories {
  USECASES = 'USECASES',
  TECH = 'tech',
  PRODUCT = 'product',
  NEWS = 'NEWS',
  TIPS = 'TIPS',
  MARKET = 'MARKET',
  COREVALUES = 'COREVALUES',
}

export const articles: Article[] = [
  // { title: 'Article 1', subtitle: 'Le premier article', paragraphs: [{ title: 'Hello', content: 'My name is WHAT' }] },
  {
    urlName: 'qu-est-ce-qu-une-specification',
    category: Categories.PRODUCT,
    title: `Expression de besoin, Cahier des charges, Spécification... comment s'y retrouver ?`,
    image:
      'https://img.freepik.com/vecteurs-libre/document-electronique-papier-electronique-bureau-sans-papier-article-internet-organisation-documentation-ligne-taper-fichier-texte-ordinateur-illustration-metaphore-concept-isole-vecteur_335657-2745.jpg?size=626&ext=jpg&ga=GA1.1.1654783496.1707240308&semt=sph',
    subtitle:
      'La spécification est souvent mal interprétée et considérée uniquement comme une documentation figée. Cependant, elle a un rôle bien plus important. Elle est un élément dynamique qui fait le lien entre les besoins des utilisateurs, les contraintes techniques et les échanges informels qui se produisent tout au long du processus de développement.',
    paragraphs: [
      {
        title: `Besoins & Idées`,
        subparagraphs: [
          {
            title: '',
            content: `La spécification recouvre tous les éléments qui permettent de préciser le besoin de l'utilisateur final et la réponse technique qui va être apportée pour répondre à ce besoin. peut sembler être une série de documents techniques détaillant les fonctionnalités du produit tel qu'il est souhaité par l'utilisateur. Néanmoins, son rôle va bien au-delà de cette vision statique. La spécification agit comme une mosaïque, rassemblant les idées et les besoins des parties prenantes. Des utilisateurs finaux aux développeurs, chaque pièce de cette mosaïque contribue à la compréhension globale du produit visé.`,
          },
        ],
      },
      {
        title: `Expression de besoin, Cahier des Charges, Spécification`,
        subparagraphs: [
          {
            title: '',
            content: `La spécification ne se limite pas à énumérer simplement les fonctionnalités attendues. Elle inclut également les contraintes techniques, les limites et les exigences de performance qui encadrent le processus de développement. Ces contraintes définissent le cadre dans lequel les développeurs évoluent, guidant leurs choix et orientant la conception.
        }
        ]

        Les échanges informels, souvent sous-estimés, sont des éléments cruciaux de la spécification. Des réunions impromptues aux discussions informelles autour de la machine à café, ces moments peuvent apporter des nuances, de nouvelles idées ou des perspectives souvent absentes dans une documentation formelle. Ils font partie intégrante du processus de spécification, contribuant à une coUne compréhension plus approfondie et holistique du produit à développer.`,
          },
        ],
      },
    ],
    conclusion: '',
  },
  {
    urlName: 'types-de-specification',
    category: Categories.PRODUCT,
    title: `Les différents types de spécification`,
    image:
      'https://img.freepik.com/vecteurs-libre/document-electronique-papier-electronique-bureau-sans-papier-article-internet-organisation-documentation-ligne-taper-fichier-texte-ordinateur-illustration-metaphore-concept-isole-vecteur_335657-2745.jpg?size=626&ext=jpg&ga=GA1.1.1654783496.1707240308&semt=sph',

    subtitle: '',

    paragraphs: [
      {
        title: `Spécification fonctionnelle`,
        subparagraphs: [
          {
            title: '',
            content: `La spécification fonctionnelle donne l'ensemble des éléments requis pour comprendre le fonctionnement du produit. Elle décrit les fonctionnalités attendues, les interactions entre les différentes parties du produit, les rôles des utilisateurs et les résultats attendus. Elle est souvent utilisée pour définir les besoins des utilisateurs finaux et les attentes des parties prenantes.  `,
          },
        ],
      },
      {
        title: `Contenu d'une spécification fonctionnelle`,
        subparagraphs: [
          {
            title: '',
            content: `La spécification fonctionnelle est souvent composée de plusieurs éléments. Elle peut inclure des diagrammes de cas d'utilisation, des maquettes, des descriptions textuelles, des scénarios d'utilisation, des exigences de performance, des contraintes techniques, des exigences de sécurité, des exigences de qualité, des exigences de maintenance, des exigences de documentation, des exigences de formation, des exigences de test, des exigences de déploiement, des exigences de support, des exigences de migration, des exigences de configuration, des exigences de compatibilité, des exigences de conformité, des exigences de réglementation, des exigences de législation, des exigences de normes, des exigences de licences, des exigences de brevets, des exigences de droits d'auteur. Elle doit être suffisamment détaillée pour permettre aux développeurs de comprendre les attentes des utilisateurs et de concevoir le produit en conséquence. Elle doit également être suffisamment flexible pour permettre des ajustements en cours de développement.`,
          },
        ],
      },
    ],
    conclusion: '',
  },
  {
    urlName: 'types-de-specification',
    category: Categories.PRODUCT,
    title: `La spécification est vivante ou n'est pas !`,
    image:
      'https://img.freepik.com/vecteurs-libre/document-electronique-papier-electronique-bureau-sans-papier-article-internet-organisation-documentation-ligne-taper-fichier-texte-ordinateur-illustration-metaphore-concept-isole-vecteur_335657-2745.jpg?size=626&ext=jpg&ga=GA1.1.1654783496.1707240308&semt=sph',

    subtitle: '',
    paragraphs: [
      {
        title: `Spécification fonctionnelle`,
        subparagraphs: [
          {
            title: '',
            content: `La spécification fonctionnelle donne l'ensemble des éléments requis pour comprendre le fonctionnement du produit. Elle décrit les fonctionnalités attendues, les interactions entre les différentes parties du produit, les rôles des utilisateurs et les résultats attendus. Elle est souvent utilisée pour définir les besoins des utilisateurs finaux et les attentes des parties prenantes.  `,
          },
        ],
      },
      {
        title: `Contenu d'une spécification fonctionnelle`,
        subparagraphs: [
          {
            title: '',
            content: `La spécification fonctionnelle est souvent composée de plusieurs éléments. Elle peut inclure des diagrammes de cas d'utilisation, des maquettes, des descriptions textuelles, des scénarios d'utilisation, des exigences de performance, des contraintes techniques, des exigences de sécurité, des exigences de qualité, des exigences de maintenance, des exigences de documentation, des exigences de formation, des exigences de test, des exigences de déploiement, des exigences de support, des exigences de migration, des exigences de configuration, des exigences de compatibilité, des exigences de conformité, des exigences de réglementation, des exigences de législation, des exigences de normes, des exigences de licences, des exigences de brevets, des exigences de droits d'auteur. Elle doit être suffisamment détaillée pour permettre aux développeurs de comprendre les attentes des utilisateurs et de concevoir le produit en conséquence. Elle doit également être suffisamment flexible pour permettre des ajustements en cours de développement.`,
          },
        ],
      },
    ],
    conclusion: '',
  },
  {
    urlName: 'complexite-de-developpement-et-regles-de-gestion',
    category: Categories.PRODUCT,
    title: `Complexité des applications`,
    image:
      'https://img.freepik.com/vecteurs-libre/document-electronique-papier-electronique-bureau-sans-papier-article-internet-organisation-documentation-ligne-taper-fichier-texte-ordinateur-illustration-metaphore-concept-isole-vecteur_335657-2745.jpg?size=626&ext=jpg&ga=GA1.1.1654783496.1707240308&semt=sph',

    subtitle: '',
    paragraphs: [
      {
        title: `Pourquoi mesurer la complexité d'une application ?`,
        subparagraphs: [
          {
            title:
              'Pour obtenir des estimations plus précises en termes de coûts et de délais de développement et de maintenance :',
            list: [
              'Une application complexe nécessitera plus de temps et sera plus coûteuse à développer.',
              ' Une application complexe peut être difficile à maintenir : les applications présentant une complexité excessive sont souvent sujettes à des erreurs, des bugs et des problèmes de performance. Mesurer la complexité permet de prévoir ces risques et de prendre des mesures pour les atténuer.',
              `Une application complexe peut poser des problèmes de performance car elle requiert plus de ressources pour s'exécuter. En mesurant la complexité, les développeurs peuvent identifier les parties de l'application qui peuvent être optimisées afin d'améliorer les performances. `,
            ],
          },
          {
            title: `Parce qu'une application complexe peut révéler un problème de conception initial. `,
            content:
              "Certains secteurs ont intrinsèquement des règles de gestion complexes, que ce soit en raison de leur étroite relation avec la législation ou de leur longue histoire avec plusieurs couches successives de développement qui s'accumulent les unes sur les autres.",
          },
          {
            title: `Afin de s'inscrire dans une démarche éco-responsable`,
            content:
              "On peut aujourd'hui comparer le secteur numérique à l'aviation car tous deux contribuent à hauteur de 4% aux émissions de gaz à effet de serre. Le lien entre la complexité d'une application et son empreinte numérique est indirect mais significatif : les applications complexes peuvent nécessiter plus de ressources matérielles et énergétiques pour s'exécuter, avoir un code source plus volumineux nécessitant plus d’espace de stockage, générer un trafic réseau plus important, etc. L’éco-conception des logiciels est une piste à ne pas négliger pour s’engager dans une démarche numérique responsable.",
          },
        ],
      },
      {
        title: `Comment mesurer la complexité d'une application ?`,
        subparagraphs: [
          {
            title: 'Le nombre de briques applicatives nécessaires pour développer un produit.',
            content: `Ce sont ce qu'on appelle les *dépendances*.
Il faut noter que cet indicateur peut exprimer des cas de complexités différents : s'il s'agit de briques applicatives à développer en interne, cela demande de dupliquer des temps de set up des environnements, de maintenance ; s'il s'agit d'intégrations avec des briques externes, la complexité résidera plutôt dans la capacité à comprendre et récupérer les informations clés.`,
          },
          {
            title: 'Le nombre de règles de gestion.',
            content: `L'un des moyens utilisés pour mesurer la complexité d'une application va être le nombre de règles de gestion à implémenter par le développeur, en front-end comme en back-end.
Les règles de sécurité basique font qu'une règle de gestion va, dans la grande majorité des cas, être dupliquée pour être vérifiée aussi bien dans les briques front-end (c'est-à-dire exposées à l'utilisateur) que dans les briques back-end.
Plus vous allez avoir de cas différents à gérer, plus le développement va devoir s'organiser en branches, nécessitant parfois de la duplication d'éléments.`,
          },
        ],
      },
      {
        title: `Comment réduire le degré de complexité d’une application ? `,
        subparagraphs: [
          {
            title: 'En avoir conscience!',
            content: `Avoir conscience du degré de complexité de votre application devrait vous aider à prendre du recul et à vous poser les bonnes questions. Lors de la conception, réfléchissez à la rédaction de vos règles de gestion et voyez comment elles peuvent être simplifiées ou factorisées.`,
          },
          {
            title: 'En comprenant les contraintes techniques pour proposer des solutions fonctionnelles acceptables.',
            content:
              'Lorsque vous achetez votre cuisine chez Ikea, il est utile de comprendre les contraintes techniques liées à votre cuisine qui vous limitent dans le choix des modèles. Cela vous fait gagner un temps précieux en le sachant dès le départ ! Il en va de même pour le développement informatique.',
          },
          {
            title: '',
            content: `Quelques questions simples à se poser :
            • Est-il possible de les écrire de manière plus simple ?
            • Y a-t-il des points communs entre mes règles de gestion qui pourraient être factorisés ?
            • Suis-je conscient de la complexité technique engendrée par mes demandes fonctionnelles ?`,
          },
        ],
      },
    ],
    conclusion:
      'En investissant du temps et des efforts pour réduire la complexité dès les premières phases de développement, vous pouvez non seulement gagner du temps (et donc de l’argent!) durant la phase de développement, mais également améliorer la maintenabilité et la durabilité de votre application.',
  },
  {
    category: Categories.PRODUCT,
    urlName: 'complexite-et-regle-de-gestions',
    title: `La complexité d'un applicatif se mesure à ses règles de gestion`,
    subtitle:
      "ces règles définissent le comportement et les interactions de l'application avec les données et les utilisateurs, et leur complexité peut avoir un impact significatif sur la conception, le développement et la maintenance du système.",
    paragraphs: [{ title: '', subparagraphs: [{ title: '', content: '' }] }],
    image: '',
    conclusion: '',
  },
];
