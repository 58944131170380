import React from 'react';
import concatClassNames from 'utils/classNames';
import {
  type TextColor,
  textColorStyle,
  textPositionStyle,
  type TextWeight,
  textWeightStyle,
} from './Blog_Components/ArticleComponents/Text/Text';
export interface HTitleProps {
  text?: string;
  color?: TextColor;
  position?: 'center' | 'left' | 'right' | 'justify';
  weight?: TextWeight;
  style?: string;
  strikethrough?: boolean;
}

export function H1Title({ text, color = 'black', position = 'right', weight = 'medium' }: HTitleProps): JSX.Element {
  return (
    <h1
      className={concatClassNames(
        'text-[40px] lg:text-[40px] xl:text-[60px] font-circularStd-Medium',
        textColorStyle[color],
        textPositionStyle[position],
        textWeightStyle[weight],
        'lg:leading-tight',
      )}
    >
      {text}
    </h1>
  );
}
